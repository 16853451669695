import React from "react";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { Button, Image } from "@chakra-ui/react";
import GoogleIcon from "../Images/Google-icon.svg";

const googleButtonStyle = {
  display: "flex",
  padding: "10px 34px",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  alignSelf: "stretch",
  borderRadius: "14px",
  background: "#021A04",
  color: "#E7EAE8",
  fontFamily: "Mona Sans",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "normal",
  boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.09)",
  width: "100%",
  cursor: "pointer",
  transition: "background 0.3s, color 0.3s",
  _hover: {
    background: "transparent",
    color: "#021A04",
  },
};

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  const login = useGoogleLogin({
    onSuccess: (response) => {
      onSuccess(response);
    },
    onError: () => {
      onFailure();
    },
    flow: "auth-code", // Use authorization code flow
    scope: "openid profile email", // Request necessary scopes
  });
  
  return (
    <Button sx={googleButtonStyle} onClick={login}>
        <Image src={GoogleIcon} alt="Google icon" boxSize="20px" />
        Sign in with Google
    </Button>
  );
};

export default GoogleLoginButton;
