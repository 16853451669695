import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Flex,
  Button,
  Heading,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import HamburgerIcon from "./HamburgerIcon.svg";
import { Link as ScrollLink } from "react-scroll";
import Logo from "./Stand-logo.png";
import LoginArrow from "./CallToActionArrowButton.svg";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  // Detect when navbar should become sticky and change background
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <Box width="100%" position="fixed" top="0" left="0" zIndex="999">
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        maxWidth="100%"
        paddingX={{ base: "14px", md: "34px" }} // Responsive padding for smaller screens
        paddingY="0px"
        bg={isSticky ? "#ffffff" : "transparent"}
        transition="background-color 0.3s ease"
      >
        <Image src={Logo} alt="logo-image" boxSize = "134px" />

        {!isMobile && (
          <Flex gap="45px" justifyContent="center" flexWrap="wrap">
            {["Home", "Product", "Features", "About Us"].map((item) => {
              const section = item.toLowerCase().replace(/\s+/g, "");
              return (
                <Box
                  key={item}
                  padding="6px 18px"
                  alignItems="center"
                  borderRadius="8px"
                  //backgroundColor = "#F2F1F0"
                  _hover={{
                    borderColor: "#282A29", 
                    backgroundColor: "transparent", 
                  }}
                

                >
                  <ScrollLink
                    to={section === "aboutus" ? "aboutUs" : section === "features" ? "features" : section} 
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-70}
                  >
                    <Link
                      href="#"
                      color = "#282A29"
                      fontSize="14px"
                      fontWeight="400"
                      fontStyle="normal"
                      _hover={{
                        textDecoration: "none", 
                        color: "#282A29", 
                      }}
                    >
                      {item}
                    </Link>
                  </ScrollLink>
                </Box>
              );
            })}
          </Flex>
        )}

        {!isMobile && (
          <Button
            variant="unstyled"
            borderRadius="14px"
            border="1px solid"
            borderColor="#4BFF5C"
            backgroundColor="#4BFF5C"
            color="#021A04"
            _hover={{
              color: "#282A29",
              borderColor: "#282A29",
              backgroundColor: "transparent", 
            }}
            onClick={handleLoginClick} 
          >
            <Flex padding="8.37px 28.459px" alignItems="center" gap="3.348px">
              <Heading fontSize="12px" fontWeight="400">
                Login
              </Heading>
              <Image src={LoginArrow} alt="login-arrow" />
            </Flex>
          </Button>
        )}

        {isMobile && (
          <IconButton
            icon={<Image src={HamburgerIcon} alt="hamburger-icon" boxSize="25px" />}
            aria-label="Open Menu"
            onClick={onOpen}
            variant="unstyled"
            color="white"
          />
        )}
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size = "full" >
        <DrawerOverlay>
          <DrawerContent bg="#ffffff" color="#282A29">
            <DrawerCloseButton color="#282A29" />
            <DrawerBody display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
              <Flex flexDirection="column" alignItems="center" gap="20px" >
                {["Home", "Product", "Features", "About Us"].map((item) => (
                  <ScrollLink
                    key={item}
                    to={item === "About Us" ? "aboutUs" : item.toLowerCase()}
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-70}
                    onClick={onClose}
                  >
                    <Link fontSize="16px" fontWeight="400" _hover={{ color: "#4BFF5C" }}>
                      {item}
                    </Link>
                  </ScrollLink>
                ))}

                <Button
                  variant="unstyled"
                  borderRadius="14px"
                  border="1px solid"
                  borderColor="#4BFF5C"
                  backgroundColor="#4BFF5C"
                  _hover={{
                    color: "#282A29",
                    borderColor: "#282A29",
                    backgroundColor: "transparent", 
                  }}
                  onClick={handleLoginClick} // Handle login button click for mobile
                >
                  <Flex padding="8.37px 28.459px" alignItems="center" gap="3.348px">
                    <Heading color="#021A04" fontSize="12px" fontWeight="400">
                      Login
                    </Heading>
                    <Image src={LoginArrow} alt="login-arrow" />
                  </Flex>
                </Button>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Navbar;
