import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Product from "./Product";
import Features from "./Features";
import PaymentPlan from "./PaymentPlan";
import AboutUs from "./AboutUs";
import ToCallAction from "./ToCallAction";
import Footer from "./Footer";

const OnePageSite = () => {
  return (
    <Box
      width="100%"  
      bg="#ffffff"  
    >

      <Navbar />


      <Box id="home" width="100%" PaddingTop="285px">
        <Hero />
      </Box>
      <Box id="product" width="100%" marginTop="185px">
        <Product />
      </Box>
      <Box id="features" width="100%" marginTop="185px">
        <Features />
      </Box>
      <Box id="paymentPlan" width="100%" marginTop="185px">
        <PaymentPlan />
      </Box>
      <Box id="aboutUs" width="100%" marginTop="185px">
        <AboutUs />
      </Box>
      <Box width="100%" marginTop="285px">
        <ToCallAction />
      </Box>
      <Box width="100%" marginTop="143px">
        <Footer />
      </Box>
    </Box>
  );
};

export default OnePageSite;
