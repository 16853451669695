import React, { useState } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import Header from './Header';

const Layout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Flex>
      <Box
        width={{ base: isSidebarOpen ? "215px" : "0", md: "215px" }}
        position="fixed"
        top="0"
        left="0"
        height="100vh"
        zIndex="999"
        transition="width 0.3s ease"
        overflow="hidden"
      >
        <Sidebar isOpen={isSidebarOpen} onToggle={() => setSidebarOpen(!isSidebarOpen)} />
      </Box>

      {/* Main content area */}
      <Box
       ml={isSidebarOpen ? "215px" : "80px"}
        transition="margin-left 0.3s ease, width 0.3s ease"
        //width={`calc(100% - ${isSidebarOpen ? "215px" : "80px"})`}
        zIndex="1000"

      >
        {/* Header */}
        <Box>
          <Header onSidebarToggle={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        </Box>

        {/* Content */}
        <Box marginTop="100px" padding="24px">
          {children}
        </Box>
      </Box>
    </Flex>
  );
};

export default Layout;
