import React, { useState } from "react";
import { Box, Flex, IconButton, Image, Text, Popover, PopoverTrigger, PopoverContent, PopoverBody, VStack,  } from "@chakra-ui/react";
import CursorIcon from "../Images/cursor-icon.svg"; // Import for Cursor Reset
import TextIcon from "../Images/Text-icon.svg";
import AnnotateIcon from "../Images/annotate-icon.svg";
import EraserIcon from "../Images/eraser.svg";
import TextCursorIcon from "../Images/Textbox-Cursor-Icon.svg";
import AnnotateCursorIcon from "../Images/Pen-Cursor-Icon.svg";
import EraserCursorIcon from "../Images/Eraser-Cursor-Icon.svg";
import twoPxStrokeWidth from "../Images/2px-stroke-width.svg";
import fourPxStrokeWidth from "../Images/4px-stroke-width.svg";
import sixPxStrokeWidth from "../Images/6px-stroke-width.svg";

const ToolBar = ({ onAddText, onAnnotate, onErase, onStrokeWidthChange, activeTool, onResetCursor  }) => {
  const [strokeWidth, setStrokeWidth] = useState(1);
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const handleCursorReset = () => {
    document.body.style.cursor = "auto";
    onResetCursor();
  };

  const handleTextIconClick = () => {
    const isTextToolActive = activeTool === "text";
    onAddText();
    document.body.style.cursor = isTextToolActive ? "auto" : `url(${TextCursorIcon}) 0 0, auto`;
  };

  const handleAnnotationIconClick = () => {
    const isAnnotateToolActive = activeTool === "pen";
    onAnnotate();
    document.body.style.cursor = isAnnotateToolActive ? "auto" : `url(${AnnotateCursorIcon}) 0 32, auto`;
  };

  const handleEraserClick = () => {
    const isEraserToolActive = activeTool === "eraser";
    onErase();
    document.body.style.cursor = isEraserToolActive ? "auto" : `url(${EraserCursorIcon}) 0 32, auto`;
  };

  const handleStrokeWidthChange = (width) => {
    setStrokeWidth(width);
    onStrokeWidthChange(width);
    setPopoverOpen(false);
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      gap={14}
      position="fixed"
      top="50%"
      left="16px"
      transform="translateY(-50%)"
      backgroundColor = "#ffffff"
      //backgroundColor="#070707"
      borderRadius="24px"
      padding="14px"
      boxShadow="0px 0px 9.4px 0px rgba(0, 0, 0, 0.18)"
      zIndex="999"
    >
      {/* Cursor Reset Tool */}
      <Box textAlign="center">
        <IconButton
          icon={<Image src={CursorIcon} alt="Cursor Reset" />}
          onClick={handleCursorReset}
          aria-label="Cursor Reset"
          borderRadius="14px"
          backgroundColor={activeTool === null ? "#F2F1F0" : "#4BFF5C"}
          size="lg"
          _hover={{ bg: "#F2F1F0" }}
          _active={{ bg: "#F2F1F0", borderColor: "#070707" }}
          transition="background-color 0.2s ease"
        />
      </Box>

      {/* Existing Tools */}
      <Box textAlign="center">
        <IconButton
          icon={<Image src={TextIcon} alt="Add Text" />}
          onClick={handleTextIconClick}
          aria-label="Add Text"
          borderRadius="14px"
          backgroundColor={activeTool === "text" ? "#F2F1F0" : "#4BFF5C"}
          size="lg"
          _hover={{ bg: "#F2F1F0" }}
          _active={{ bg: "#F2F1F0" }}
          transition="background-color 0.2s ease"
        />
        </Box>

      <Box textAlign="center">
        <Popover isOpen={isPopoverOpen} onClose={() => setPopoverOpen(false)} trigger="click" placement="right">
          <PopoverTrigger>
            <IconButton
              icon={<Image src={AnnotateIcon} alt="Annotate" />}
              onClick={() => {
                handleAnnotationIconClick();
                setPopoverOpen(true);
              }}
              aria-label="Annotate"
              borderRadius="14px"
              backgroundColor={activeTool === "pen" ? "#F2F1F0" : "#4BFF5C"}
              size="lg"
              _hover={{ bg: "#F2F1F0" }}
              _active={{ bg: "#F2F1F0" }}
              transition="background-color 0.2s ease"
            />
          </PopoverTrigger>
          <PopoverContent width="104px" bg="#ffffff" boxShadow = "none" border="0.4px" borderRadius="8px">
            <PopoverBody>
              <VStack spacing={3}>
                <Text fontSize="12px" color="#070707"> Line Thickness </Text>
                {[2, 4, 6].map((width) => (
                  <IconButton
                    key={width}
                    icon={<Image src={width === 2 ? twoPxStrokeWidth : width === 4 ? fourPxStrokeWidth : sixPxStrokeWidth} alt={`${width}px Stroke Width`} />}
                    onClick={() => handleStrokeWidthChange(width)}
                    aria-label={`${width}px Stroke Width`}
                    backgroundColor={strokeWidth === width ? "#F2F1F0" : "transparent"}
                    _hover={{ backgroundColor: "#F2F1F0" }}
                  />
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        </Box>

      <Box textAlign="center">
        <IconButton
          icon={<Image src={EraserIcon} alt="Erase" />}
          onClick={handleEraserClick}
          aria-label="Erase"
          borderRadius="14px"
          backgroundColor={activeTool === "eraser" ? "#F2F1F0" : "#4BFF5C"}
          size="lg"
          _hover={{ bg: "#F2F1F0" }}
          _active={{ bg: "#F2F1F0" }}
          transition="background-color 0.2s ease"
        />
      </Box>
    </Flex>
  );
};

export default ToolBar;
