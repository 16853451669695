import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CreateUserAttempt from './CreateUserAttempt';
import HandIcon from "../Images/handIcon.svg";
import PerformanceCardDashboard from "./PerformanceCardDashboard";
import SubjectTopicTracker from "./SubjectTopicTracker";
import { BASE_API_URL } from './config';

const DashboardMain = () => {
    const [userAttempts, setUserAttempts] = useState([]);
    const [userName, setUserName] = useState(null);
    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const tokenType = localStorage.getItem('tokenType');
                if (!accessToken || !tokenType) {
                    throw new Error('Authorization token not found');
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `${tokenType} ${accessToken}`,
                };

                const response = await fetch(`${BASE_API_URL}/api/v1/users/own`, {
                    method: 'GET',
                    headers: headers,
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch from the API');
                }
                const data = await response.json();
                setUserName(data.first_name);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, []);

    useEffect(() => {
        const fetchUserAttempts = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const tokenType = localStorage.getItem('tokenType');
                if (!accessToken || !tokenType) {
                    throw new Error('Authorization token not found');
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `${tokenType} ${accessToken}`,
                };

                const response = await fetch(`${BASE_API_URL}/api/v1/user-paper-attempts/detail/?limit=3`, {
                    method: 'GET',
                    headers: headers,
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch from the API');
                }
                const data = await response.json();
                setUserAttempts(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserAttempts();
    }, []);

    return (
        <Flex overflowX="hidden" >
            <Box

                width="calc(100vw - 252px)"
            >

                <Box spacing={4}>
                    <Flex flexDirection="row" alignItems="center" gap={2} mb={8}>
                        <Heading
                        color="#021A04"
                        fontSize="28px"
                        fontWeight="500"
                    >  Hi, {userName}</Heading>
                        <Image src={HandIcon} alt="Hand-Icon" />
                    </Flex>

                    <Flex
                        height="108px"
                        width="473px"
                        gap="103px"
                        flexDirection = {{ base: "column", lg: "row", sm: "column", md: "column"}}
                        mb = {{ base: "254px", lg: "24px"}}
                    >
                        <PerformanceCardDashboard />
                        
                    </Flex>

                    <Flex flexDirection = {{ base: "column", lg: "row", sm: "column", md: "column"}}gap = {{ base: "8px", lg: "32px" }} marginTop="44px">
                        {/* Recently accessed papers */}
                        <Box
                            width={{ base: "400%", lg: "45%", sm: "100%", md: "100%"  }} // Adjust width to fit the available space
                            height="400px"
                            borderRadius="14px"
                            border="2px solid #E7EAE8"
                            padding="35px 0px 35.13px 18px"
                            alignItems="center"
                            overflowX="auto"
                            css={{
                                '&::-webkit-scrollbar': { width: '0px' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none',
                            }}
                            position="relative"
                        >
                            <Heading
                                color="#021A04"
                                fontSize="20px"
                                fontWeight="400"
                                fontStyle="normal"
                                position = "absolute"
                            > Your most recent papers </Heading>
                            <Flex
                                flexDirection="row"
                                gap="16px"
                                alignItems="center"
                                justifyContent="flex-start"
                                width = "max-content"
                                marginTop="44px"
                                onMouseDown={e => {
                                    const container = e.currentTarget;
                                    let isDown = false;
                                    let startX;
                                    let scrollLeft;

                                    container.addEventListener('mousedown', (e) => {
                                        isDown = true;
                                        container.classList.add('active');
                                        startX = e.pageX - container.offsetLeft;
                                        scrollLeft = container.scrollLeft;
                                    });

                                    container.addEventListener('mouseleave', () => {
                                        isDown = false;
                                        container.classList.remove('active');
                                    });

                                    container.addEventListener('mouseup', () => {
                                        isDown = false;
                                        container.classList.remove('active');
                                    });

                                    container.addEventListener('mousemove', (e) => {
                                        if (!isDown) return;
                                        e.preventDefault();
                                        const x = e.pageX - container.offsetLeft;
                                        const walk = (x - startX) * 3;
                                        container.scrollLeft = scrollLeft - walk;
                                    });
                                }}
                            >
                                {userAttempts.length > 0 ? (
                                    userAttempts.map((attempt, index) => (
                                        <CreateUserAttempt
                                            key={index}
                                            subjectName={`${attempt.subject_name} ${attempt.subject_code}`}
                                            paperName={`Paper ${attempt.paper_name.split(' ')[1]}${attempt.paper_variant} ${attempt.exam_series_month === "MAY_JUNE" ? "May/June" : attempt.exam_series_month} ${attempt.exam_series_year}`}
                                            numOfQuestionsLeft={attempt.questions_remaining}
                                            mark={attempt.total_scored_marks}
                                            totalMark={attempt.total_marks}
                                            inProgress={attempt.completion_status !== "COMPLETE"}
                                            progressPercentage={Math.round(attempt.percentage_complete)}
                                            user_paper_attempt_guid={attempt.guid}
                                        />
                                    ))
                                ) : (
                                    <Text marginTop="24px" color="#535953" fontSize="12px" fontWeight="200"> You have not attempted to solve any papers yet</Text>
                                )}
                            </Flex>
                        </Box>

                        {/* Subject Topic Tracker */}
                        <Box width = {{ base: "50%", lg: "75%" }} >
                            <SubjectTopicTracker  />
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};

export default DashboardMain;
