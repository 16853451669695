import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";


const isTokenExpired = (token) => {

  if (!token) return true; // Return true if no token is provided

  try {

      const decoded = jwtDecode(token);

      return decoded.exp < (Date.now() / 1000);

  } catch (error) {

      console.error('Error decoding token:', error);

      return true; // Consider token expired if decoding fails

  }

}


const PrivateRoute = ({ children }) => {

    const accessToken = localStorage.getItem('accessToken');
    const isAuthenticated = accessToken && !isTokenExpired(accessToken);

    if(!isAuthenticated){
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('userGuid');
    localStorage.removeItem('subscriptionPlanGuid');
    localStorage.removeItem('firstLetter');
    return <Navigate to="/login" replace />;
  }
  return children
  




};

export default PrivateRoute;
