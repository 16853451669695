import React from "react";
import { Box, Grid, Flex, Heading, Text, Image, Link } from "@chakra-ui/react";
import footerLogo from "./Stande-logo-dark.png";
import twitterIcon from "./Twitter.svg";
import facebookIcon from "./facebook.svg";
import instagramIcon from "./instagram.svg";
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  const listItemStyle = {
    color: "#ffffff",
    opacity: 0.9,
    fontSize: "sm",
    fontWeight: "normal",
    _hover: {
      textDecoration: "none", 
      opacity: 0.7, 
    }
  
  };

  return (
    <Box as="footer" bg="black" py={8} width="100%" marginTop="195px" paddingX={{ base: "20px", md: "45px" }} borderRadius = "24px 24px 0px 0px">
      <Box mx="auto">
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} // Adjusted to 3 columns for large screens, stacked on mobile
          gap={8} // Reduced gap for mobile
          textAlign={{ base: "center", md: "left" }}
          paddingTop="54px"
        >
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", md: "flex-start" }}
            color="white"
            fontSize="xl"
            fontWeight="medium"
            fontFamily="'Mona Sans', sans-serif"
          > 
            <Image src = {footerLogo} boxSize = "124px" />
          </Flex>

          <Flex flexDirection="column" alignItems={{ base: "center", md: "flex-start" }} paddingTop="54px">
            <Heading as="h2" color="#d9d9d9" fontSize="lg" fontWeight="semibold">
              Links
            </Heading>
            <Flex flexDirection="row" gap={2} marginTop="14px">
              <ScrollLink to="home" smooth={true} duration={500} offset={-70}>
                <Link {...listItemStyle}>Home</Link>
              </ScrollLink>
              <ScrollLink to="product" smooth={true} duration={500} offset={-70}>
                <Link {...listItemStyle}>Product</Link>
              </ScrollLink>
              <ScrollLink to="features" smooth={true} duration={500} offset={-70}>
                <Link {...listItemStyle}>Features</Link>
              </ScrollLink>
              <ScrollLink to="aboutUs" smooth={true} duration={500} offset={-70}>
                <Link {...listItemStyle}>About Us</Link>
              </ScrollLink>
            </Flex>
          </Flex>

          <Flex flexDirection="column" alignItems={{ base: "center", md: "flex-start" }} paddingTop="54px">
            <Heading as="h2" color="#d9d9d9" fontSize="lg" fontWeight="semibold">
              Contact
            </Heading>
            <Flex flexDirection="column" gap={2} marginTop="14px"> {/* Adjusted layout */}
              <Heading as="h3" color="#e7eae6" fontSize="md" fontWeight="150">
                Email:admin@stande.co
              </Heading>
              
            </Flex>
          </Flex>
        </Grid>

        <Flex
          mt={8}
          pt={8}
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          textAlign={{ base: "center", md: "left" }}
        >
          <Text color="#e7eae6" opacity={0.6} fontSize="sm">
            © 2024 All rights reserved
          </Text>
          <Flex mt={{ base: 4, md: 0 }} gap={4}>
            <Image src={twitterIcon} alt="Twitter-icon" boxSize={6} />
            <Image src={facebookIcon} alt="Facebook-icon" boxSize={6} />
            <Image src={instagramIcon} alt="Instagram-icon" boxSize={6} />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;