import React, { useState, useEffect } from "react";
import { Box, Heading, Flex, Image, VStack, Input, Button, Text } from "@chakra-ui/react";
import ArrowIcon from "./Landing Page/ExpandArrow.svg"; // Arrow for expand/collapse
import EditIcon from "../Images/edit-icon.svg"; // Edit icon
import TickIcon from "../Images/tick-icon.svg"; // Tick for active plan
import Arrow from "./Landing Page/CallToActionArrowButton.svg"; // Arrow for buttons
import { BASE_API_URL } from './config';

const PlanCards = ({ plans, activePlanGuid }) => {

  const [monthlyPremiumStripePriceId, setMonthlyPremiumStripePriceId] = useState(null);
  const [yearlyPremiumStripePriceId, setYearlyPremiumStripePriceId] = useState(null);

  const premiumSubscriptionPlanGuid = "298eb053-7c3a-45d6-967f-f73508517aa9";

  useEffect(() => {
    const fetchPriceId = async () => {
      try {
        const response = await fetch(
          `https://stand-ai-api-v1.onrender.com/api/v1/subscription-plans/${premiumSubscriptionPlanGuid}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch subscription plan details");
        }
        const data = await response.json();
        setMonthlyPremiumStripePriceId(data.stripe_price_id_monthly);
        setYearlyPremiumStripePriceId(data.stripe_price_id_yearly);
      } catch (error) {
        console.error("Error fetching price ID:", error);
      }
    };

    fetchPriceId();
  }, []);

  const handleUpgradeClick = async (planType) => {
    const token = `Bearer ${localStorage.getItem('accessToken')}`;

    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/user-subscription-plans/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          subscription_plan_guid: "298eb053-7c3a-45d6-967f-f73508517aa9",
          stripe_price_id: monthlyPremiumStripePriceId,
          renewal_type:"MONTHLY",
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const data = await response.json();
      const checkoutUrl = data.checkout_url;

      // Redirect user to the checkout page
      window.location.href = checkoutUrl;
    } catch (err) {
      console.error('Error creating checkout session:', err.message);
    }
  };

  // Handle manage subscription functionality (API call)
  const handleManageSubscriptionClick = async () => {
    const token = `Bearer ${localStorage.getItem("accessToken")}`;
  
    try {
      const userResponse = await fetch(`${BASE_API_URL}/api/v1/users/own`, {
        headers: {
          Authorization: token,
        },
      });

      if (!userResponse.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await userResponse.json();

      const createPortalResponse = await fetch(
        `${BASE_API_URL}/api/v1/user-subscription-plans/stripe/create-billing-portal-session/${userData.stripe_customer_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          }
        }
      );
  
      if (!createPortalResponse.ok) {
        throw new Error("Failed to create checkout session");
      }
  
      const data = await createPortalResponse.json();
      const url = data.billing_portal_url;
  
      window.location.href = url;
    } catch (err) {
      console.error("Error creating checkout session:", err.message);
    }
  };

  return (
    <Flex justifyContent="space-between" width="100%" paddingTop="20px">
      {plans.map((plan, index) => {
        if (activePlanGuid && plan.PlanType === "Basic Plan") {
          return null;
        }
        // Determine button label based on the active plan and the plan type
        let buttonText;

        if (activePlanGuid === plan.guid) {
          // Current plan
          buttonText = "Manage Subscription";
        } else if (activePlanGuid && plan.PlanType === "Premium Plan") {
          // Premium plan is not current plan
          buttonText = "Upgrade to Premium";
        } else {
          buttonText = plan.PlanType === "Premium Plan" ? "Upgrade to Premium" : "Downgrade to Basic";
        }

        return (
          <Flex
            key={index}
            width={{ base: "100%", md: "400px" }}
            padding="30px 20px"
            flexDirection="column"
            bg={"#070707"}
            border={"none"}
            borderRadius="14px"
            gap="30px"
            alignItems="center"
            textAlign="center"
            marginRight="34px"
          >
            <Flex justifyContent="space-between" width="100%" alignItems="center">
              <Heading color={"#FFF"} fontSize={{ base: "20px", md: "24px" }} fontWeight="600">
                {plan.PlanType}
              </Heading>
              {activePlanGuid === plan.guid && (
                <Image src={TickIcon} alt="tick-icon" marginLeft="10px" />
              )}
            </Flex>

            <Flex flexDirection="row" alignItems="center" gap="8px">
              <Heading color={"#FFF"} fontSize={{ base: "30px", md: "36px" }} fontWeight="500">
                {plan.Price}
              </Heading>
              <Flex flexDirection="column" alignItems="flex-start">
                <Text color={"#F1F1F1"} fontSize="11px" fontWeight="400">
                  per user
                </Text>
                <Text color={"#F1F1F1"} fontSize="11px" fontWeight="400">
                  {plan.period.split(" ").slice(2).join(" ")}
                </Text>
              </Flex>
            </Flex>

            <Button
              variant="unstyled"
              borderRadius="14px"
              bg={"#4BFF5C"}
              width="180px"
              border="1px solid"
              borderColor={"none"}
              color={"#08250B"}
              _hover={{
                backgroundColor: "transparent",
                borderColor: "#F1F1F1",
                color: "#F1F1F1"
              }}
              onClick={() => activePlanGuid === plan.guid ? handleManageSubscriptionClick() : handleUpgradeClick(plan.guid, plan.PlanType)}
              disabled={buttonText === "Current Plan"}
            >
              <Flex padding="8px 20px" gap="4px" alignItems="center" justifyContent="center">
                <Heading fontSize="12px" fontWeight="400">
                  {buttonText}
                </Heading>
                <Image src={Arrow} alt="get-started-arrow-button-icon" />
              </Flex>
            </Button>
          </Flex>
        );
      })}
    </Flex>
  );
};


// Main Settings Component
const Settings = () => {
  const [expandedStep, setExpandedStep] = useState(null); 
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
  });
  const [isEditingField, setIsEditingField] = useState(null); 
  const [activePlanGuid, setActivePlanGuid] = useState('');
  const [error, setError] = useState(null); 

  const toggleExpand = (index) => {
    setExpandedStep(expandedStep === index ? null : index);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = `Bearer ${localStorage.getItem('accessToken')}`;
      try {
        const response = await fetch(`${BASE_API_URL}/api/v1/users/own`, {
          headers: {
            Authorization: token,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        setUserData({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          dob: data.dob,
        });
        setActivePlanGuid(data.active_subscription_plan_guid || null); 
      } catch (err) {
        setError(err.message);
      }
    };
    fetchUserData();
  }, []);

  const handleSave = async () => {
    const token = `Bearer ${localStorage.getItem('accessToken')}`;
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/users/own`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(userData),
      });
      if (!response.ok) {
        throw new Error("Failed to save user data");
      }
      setIsEditingField(null); 
    } catch (err) {
      setError(err.message); 
    }
  };

  const plans = [
    {
      PlanType: "Basic Plan",
      Price: "$0",
      period: "per user per month",
      guid: "9d69499f-88eb-483b-a9fc-e07f07873319",
    },
    {
      PlanType: "Premium Plan",
      Price: "$18.90",
      period: "per user per month",
      isPopular: true,
      guid: "298eb053-7c3a-45d6-967f-f73508517aa9",
    },
  ];

  return (
    <Box>
      <Heading fontSize="24px" fontWeight="500" marginBottom="16px">Settings</Heading>
      <VStack width={{ base: "100%", lg: "700px" }} spacing="20px">
        
        <Box
          height={expandedStep === 0 ? "auto" : "70px"}
          transition="height 0.3s ease"
          padding={expandedStep === 0 ? "13px 28px" : "15px 10px"}
          borderRadius="14px"
          border="1px solid var(--Primary, #F1F1F1)"
          width="100%"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex width="100%" justifyContent="space-between" alignItems="center" padding="0 20px">
              <Heading fontSize="14px" fontWeight="400" color="#021A04">
                Personal Information
              </Heading>
              <Box
                onClick={() => toggleExpand(0)}
                cursor="pointer"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="10px"
              >
                <Image
                  src={ArrowIcon}
                  alt="expand-collapse-arrow"
                  transform={expandedStep === 0 ? 'rotate(180deg)' : 'rotate(0deg)'}
                />
              </Box>
            </Flex>
          </Flex>

          {expandedStep === 0 && (
            <VStack paddingTop="20px" spacing="8px">
              <Flex justifyContent="space-between" width="100%">
                <Flex flexDirection="column" width="100%" alignItems="flex-start" gap="5px">
                  <Flex alignItems="center" gap="4px">
                    <Text fontSize="14px" fontWeight="400" color="#021A04">First Name</Text>
                    <Image
                      src={EditIcon}
                      alt="edit"
                      cursor="pointer"
                      onClick={() => setIsEditingField('first_name')}
                    />
                  </Flex>
                  {isEditingField === 'first_name' ? (
                    <Input
                      value={userData.first_name}
                      onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}
                      fontSize="10px"
                      fontWeight="300"
                      autoFocus
                    />
                  ) : (
                    <Text fontSize="12px" fontWeight="300" color="#021A04">{userData.first_name}</Text>
                  )}
                </Flex>

                <Flex flexDirection="column" width="100%" alignItems="flex-start" gap="5px">
                  <Flex alignItems="center" gap="4px">
                    <Text fontSize="14px" fontWeight="400" color="#021A04">Last Name</Text>
                    <Image
                      src={EditIcon}
                      alt="edit"
                      cursor="pointer"
                      onClick={() => setIsEditingField('last_name')}
                    />
                  </Flex>
                  {isEditingField === 'last_name' ? (
                    <Input
                      value={userData.last_name}
                      onChange={(e) => setUserData({ ...userData, last_name: e.target.value })}
                      fontSize="10px"
                      fontWeight="300"
                      autoFocus
                    />
                  ) : (
                    <Text fontSize="12px" fontWeight="300" color="#021A04">{userData.last_name}</Text>
                  )}
                </Flex>
              </Flex>

              <Flex justifyContent="space-between" width="100%">
                <Flex flexDirection="column" width="100%" alignItems="flex-start" gap="5px">
                  <Flex alignItems="center" gap="4px">
                    <Text fontSize="14px" fontWeight="400" color="#021A04">Email</Text>
                    <Image
                      src={EditIcon}
                      alt="edit"
                      cursor="pointer"
                      onClick={() => setIsEditingField('email')}
                    />
                  </Flex>
                  {isEditingField === 'email' ? (
                    <Input
                      value={userData.email}
                      onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      fontSize="14px"
                      fontWeight="300"
                      autoFocus
                    />
                  ) : (
                    <Text fontSize="12px" fontWeight="300" color="#021A04">{userData.email}</Text>
                  )}
                </Flex>
              </Flex>

              {isEditingField && (
                <Button onClick={handleSave} colorScheme="black" mt="8px">
                  Save
                </Button>
              )}
              {error && (
                <Text color="red.500" mt="8px">{error}</Text>
              )}
            </VStack>
          )}
        </Box>

        <Box
          height={expandedStep === 1 ? "auto" : "70px"}
          transition="height 0.3s ease"
          padding={expandedStep === 1 ? "13px 28px" : "15px 10px"}
          borderRadius="14px"
          border="1px solid var(--Primary, #F1F1F1)"
          width="100%"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex width="100%" justifyContent="space-between" alignItems="center" padding="0 20px">
              <Heading fontSize="14px" fontWeight="400" color="#021A04">
                Account Plan
              </Heading>
              <Box
                onClick={() => toggleExpand(1)}
                cursor="pointer"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="10px"
              >
                <Image
                  src={ArrowIcon}
                  alt="expand-collapse-arrow"
                  transform={expandedStep === 1 ? 'rotate(180deg)' : 'rotate(0deg)'}
                />
              </Box>
            </Flex>
          </Flex>
          {expandedStep === 1 && (
            <PlanCards plans={plans} activePlanGuid={activePlanGuid} />
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default Settings;
