import React, { useState, useEffect } from 'react';
import { Box, Heading, Image, VStack, Flex, Button, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import DashboardIcon from "../Images/Dashboard-icon.svg";
import PastPapers from "../Images/Past-papers-icon.svg";
import MyPapers from "../Images/My-papers-icon.svg";
import MySubjects from "../Images/My-subjects-icon.svg";
import SettingsIcon from "../Images/Settings-icon.svg";
import LogoutIcon from "../Images/Logout-icon.svg";
import logo from "./Landing Page/Stand-logo.png";
import { BASE_API_URL } from './config';
import SidebarIcon from "../Images/sidebar-icon.svg";
import SidebarIconE from "../Images/Stand-logo-Icon-E-1.png";

import { useMediaQuery } from "@chakra-ui/react";


const Sidebar = ({ isOpen, onToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const hoverStyle = {
    background: "#ffffff",
    borderRadius: "0px", 
    borderLeft: '2px solid #4BFF5C',
   
  };

  const activeButtonStyle = {
    background: "#ffffff",
    borderRadius: "0px", 
    borderLeft: '2px solid #4BFF5C',
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${BASE_API_URL}/api/v1/auth/sign-out`);
      if (response.data.msg === "Signed out successfully") {
        localStorage.clear();
        navigate('/login');
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      {isMobile && (
        <IconButton
          icon={<Image src={SidebarIcon} boxSize="24px" />}
          background="transparent" 
          _hover={{ background: "none" }}
          _active={{ background: "none" }}
          aria-label="Toggle Sidebar"
          position="fixed"
          top="16px"
          right = "-30px"
          onClick={onToggle}
          zIndex="overlay"
          backgroundColor="#ffffff"
          color="white"
        />
      )}

      <Box
        bg="#ffffff"
        width={isOpen ? "215px" : "80px"}
        height="100vh"
        position="fixed"
        left={isMobile && !isOpen ? "-234px" : "0"}
        top="0px"
        transition="width 0.3s ease"
        zIndex={isMobile && isOpen ? "overlay" : "auto"}
        borderRight="1.5px solid #E7EAE8"
        display="flex"
        flexDirection="column"
      > 
        <Flex paddingLeft={isOpen ? "41px" : "12px"} height="100vh" flexDirection="column">
          <VStack align="left" spacing={4} flex="1">
            <Flex gap="4px" align="left" width="100%" justifyContent="left">
              <Image src={isOpen ? logo: SidebarIconE} alt="stande.ai-logo" 
                boxSize={isOpen ? "124px" : "42px"} 
                transition="all 0.3s ease" 
              />
              <IconButton
                icon={<Image src={SidebarIcon} boxSize="24px" />}
                background="transparent"
                _hover={{ background: "none" }}
                _active={{ background: "none" }} 
                aria-label="Collapse Sidebar"
                onClick={onToggle}
                size="sm"
                ml={isOpen ? "auto" : "0"}
              />
            </Flex>

            {isOpen && (
              <Heading as="h2" fontSize="14px" fontWeight="450" color="#282A29">
                Overview
              </Heading>
            )}

            <Button
              gap="8px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="left"
              sx={activePath === '/home' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/home')}
            >
              <Image src={PastPapers} alt="past-paper-icon" 
                boxSize={isOpen ? "32px" : "32px"} 
                transition="all 0.3s ease"
              />
              {isOpen && <Heading as="h1" fontSize="12px" fontWeight="350" color="#343634">Past Papers</Heading>}
            </Button>

            <Button
              gap="8px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="left"
              sx={activePath === '/dashboard' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/dashboard')}
            >
              <Image src={DashboardIcon} alt="dashboard-icon" 
                boxSize={isOpen ? "32px" : "32px"} 
                transition="all 0.3s ease"
              />
              {isOpen && <Heading as="h1" fontSize="12px" fontWeight="350" color="#343634">Dashboard</Heading>}
            </Button>

            {isOpen && (
              <>
                <Box height="25px" />
                <Heading as="h2" fontSize="14px" fontWeight="450" color="#282A29">
                  My Workspace
                </Heading>
              </>
            )}

            <Button
              gap="8px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="left"
              sx={activePath === '/my-papers' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/my-papers')}
            >
              <Image src={MyPapers} alt="my-papers-icon" 
                boxSize={isOpen ? "32px" : "32px"} 
                transition="all 0.3s ease"
              />
              {isOpen && <Heading as="h1" fontSize="12px" fontWeight="350" color="#343634">My Papers</Heading>}
            </Button>

            <Button
              gap="8px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="left"
              sx={activePath === '/my-subjects' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/my-subjects')}
            >
              <Image src={MySubjects} alt="my-subjects-icon" 
                boxSize={isOpen ? "32px" : "32px"} 
                transition="all 0.3s ease"
              />
              {isOpen && <Heading as="h1" fontSize="12px" fontWeight="400" color="#343634">My Subjects</Heading>}
            </Button>
         
          </VStack>

          {/* Settings & Logout at the Bottom */}
          <Flex direction="column" width="100%" mt="auto">
          <Button
              gap="8px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="left"
              sx={activePath === '/settings' ? activeButtonStyle : { ':hover': hoverStyle }}
              onClick={() => navigate('/settings')}
            >
              <Image src={SettingsIcon} alt="settings-icon" 
                boxSize={isOpen ? "14px" : "14px"} 
                transition="all 0.3s ease"
              />
              {isOpen && <Heading as="h1" fontSize="12px" fontWeight="400" color="#343634">Settings</Heading>}
            </Button>

            <Button
              gap="8px"
              paddingTop="10px"
              paddingBottom="10px"
              width="100%"
              variant="ghost"
              justifyContent="left"
              sx={{ ':hover': hoverStyle }}
              onClick={handleLogout}
            >
              <Image src={LogoutIcon} alt="logout-icon" 
                boxSize={isOpen ? "14px" : "14px"} 
                transition="all 0.3s ease"
              />
              {isOpen && <Heading as="h1" fontSize="12px" fontWeight="400" color="#343634">Logout</Heading>}
            </Button>

          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Sidebar;
