import React, { useState } from "react";
import { Box, VStack, Flex, Button, Heading, Text, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; 
import TickIcon from "./TickIcon.svg";
import Arrow from "./CallToActionArrowButton.svg";

const PaymentPlan = () => {
  const [isAnnually, setIsAnnually] = useState(false); 
  const navigate = useNavigate(); 

  const plans = [
    {
      PlanType: "Basic Plan",
      Price: "$0",
      period: "per user per month",
      features: [ 
        "Access to unlimited past papers across multiple exam boards and qualifications",
        "Instant marking and feedback on limited number of questions daily",
      ],
    },
    {
      PlanType: "Premium Plan",
      Price: isAnnually ? "$159.90" : "$18.90", 
      period: isAnnually ? "per user per year" : "per user per month",
      isPopular: true,
      features: [
        "Instant, Detailed Feedback: Get deep insights into your answers.",
        "Performance Tracking: Our dashboard breaks down your performance by subject and individual topics.",
        "Personalized Study Suggestions: Based on your performance, we suggest key topics to focus on to maximize your scores.",
        "Overall Subject Performance Insights: See how you're performing across an entire subject by analyzing individual topic strengths and weaknesses.",
        "Test Mode: Experience real exam conditions with Test Mode: timed sessions, full-screen lock, no copy-pasting, controlled navigation, and optional session recording for a secure and fair testing environment. "
      ],
    },
  ];

  const isAuthenticated = () => {
    return localStorage.getItem('accessToken') !== null;
  };

  const handleGetStarted = (planType) => {
    if (!isAuthenticated()) {
      sessionStorage.setItem('selectedPlan', planType);
      navigate('/login');
    } else {
      // If authenticated, proceed with the checkout API call for Premium Plan
      sessionStorage.setItem('selectedPlan', planType);  
      handleCheckout();
    }
  };

  const handleCheckout = async () => {
    const token = `Bearer ${localStorage.getItem('accessToken')}`;
    const subscriptionGuid = localStorage.getItem('subscriptionPlanGuid'); // Fetch userGuid from localStorage

    if (!subscriptionGuid) {
      console.error("User subject GUID not found.");
      return;
    }

    try {
      const response = await fetch('https://stand-ai-api-v1.onrender.com/api/v1/user-subscription-plans/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          subscription_plan_guid: "298eb053-7c3a-45d6-967f-f73508517aa9",
          stripe_price_id: isAnnually ? "price_1QuBAlC7LkUNUnN8MzPrfUzq" : "price_1QuB0bC7LkUNUnN8wWYhK9vI", // Use the correct Stripe price ID
          renewal_type: isAnnually ? "YEARLY" : "MONTHLY",
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const data = await response.json();
      const checkoutUrl = data.checkout_url;

      // Redirect user to the checkout page
      window.location.href = checkoutUrl;
    } catch (err) {
      console.error('Error creating checkout session:', err.message);
    }
  };

  return (
    <Box width="100%" paddingX={{ base: "20px", md: "45px" }} marginTop="60px">
      <VStack spacing={8} alignItems="center">
        {/* Top Section */}
        <Flex flexDirection="column" alignItems="center" width={{ base: "100%", lg: "60%" }} gap="14px" marginBottom="40px">
          <Heading color="#021A04" fontSize={{ base: "24px", md: "32px" }} fontWeight="600" textAlign="center">
            We have got a plan that is perfect for you
          </Heading>

          <Flex borderRadius="14px" border="1px solid #D7D7D7" alignItems="center" gap="4px" padding="3px">
            <Button
              variant="unstyled"
              width="121px"
              padding="10px 34px"
              alignItems="center"
              borderRadius="14px"
              bg={!isAnnually ? "#4BFF5C" : "transparent"}
              color={!isAnnually ? "#08250B" : "#08250B"}
              border={!isAnnually ? "none" : "1px solid #E7EAE8"}
              fontSize="12px"
              fontWeight="400"
              onClick={() => setIsAnnually(false)} // Set Monthly
            >
              Monthly
            </Button>
            <Button
              variant="unstyled"
              width="121px"
              padding="10px 34px"
              alignItems="center"
              borderRadius="14px"
              bg={isAnnually ? "#4BFF5C" : "transparent"}
              color={isAnnually ? "#08250B" : "#08250B"}
              border={isAnnually ? "none" : "1px solid #E7EAE8"}
              fontSize="12px"
              fontWeight="400"
              onClick={() => setIsAnnually(true)}
            >
              Annually
            </Button>
          </Flex>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }} 
          gap={{ base: "40px", lg: "50px" }} 
          width="100%"
          justifyContent="center"
        >
          {plans.map((plan, index) => (
            <Flex
              key={index}
              width={{ base: "100%", md: "450px" }} 
              padding="30px 20px" 
              flexDirection="column"
              bg="#ffffff"
              border= "2px solid #E7EAE8"
              borderRadius="14px"
              gap="30px" 
              alignItems="center"
              textAlign="center"
            >
              <Flex justifyContent="space-between" width="100%" alignItems="center">
                <Heading color="#282A29" fontSize={{ base: "20px", md: "24px" }} fontWeight="600">
                  {plan.PlanType}
                </Heading>
                {plan.isPopular && (
                  <Flex
                    display="inline-flex"
                    padding="4px 10px"
                    justifyContent="center"
                    alignItems="center"
                    gap="10px"
                    borderRadius="24px"
                    bg="linear-gradient(240deg, #0FFD26 12.06%, #7BFC87 85.61%)"
                    color="#021A04"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="normal"
                  >
                    Popular
                  </Flex>
                )}
              </Flex>

              <Flex flexDirection="row" alignItems="center" gap="8px">
                <Heading color="#282A29" fontSize={{ base: "30px", md: "36px" }} fontWeight="500">
                  {plan.Price}
                </Heading>
                <Flex flexDirection="column" alignItems="flex-start">
                  <Text color="#282A29" fontSize="11px" fontWeight="400">
                    per user
                  </Text>
                  <Text color="#343634" fontSize="11px" fontWeight="400">
                    {plan.period.split(" ").slice(2).join(" ")}
                  </Text>
                </Flex>
              </Flex>

              <Button
                variant="unstyled"
                borderRadius="14px"
                bg="#4BFF5C"
                width="180px"
                border="1px solid"
                borderColor="#4BFF5C"
                color="#021A04"
                _hover={{
                  color: "#282A29",
                  borderColor: "#282A29",
                  backgroundColor: "transparent",
                }}
                onClick={() => handleGetStarted(plan.PlanType)} 
              >
                <Flex padding="8px 20px" gap="4px" alignItems="center" justifyContent="center">
                  <Heading fontSize="12px" fontWeight="400">
                    Get Started
                  </Heading>
                  <Image src={Arrow} alt="get-started-arrow-button-icon" />
                </Flex>
              </Button>

              <Flex flexDirection="column" alignItems="flex-start" gap="18px">
                <Heading fontSize="18px" color="#282A29" fontWeight="500">
                  FEATURES
                </Heading>
                {plan.features.map((feature, i) => (
                  <Flex key={i} flexDirection="row" gap="8px" alignItems="center" textAlign="left">
                    <Image src={TickIcon} alt="tick-plan-icon" />
                    {feature.includes(":") ? (
                      <Text color="#282A29" fontSize="14px" fontWeight="500">
                        {feature.split(":")[0]}:{" "}
                        <Text as="span" color="#343634" fontSize="14px" fontWeight="400">
                          {feature.split(":")[1]}
                        </Text>
                      </Text>
                    ) : (
                      <Text color="#343634" fontSize="14px" fontWeight="400">
                        {feature}
                      </Text>
                    )}
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </VStack>
    </Box>
  );
};

export default PaymentPlan;
