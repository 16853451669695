import { Box, Flex, Text, useBreakpointValue} from "@chakra-ui/react";

const boardExams = [
  { title: "Cambridge", status: "Available" },
  { title: "Edexcel", status: "Coming Soon" },
  { title: "AQA", status: "Coming Soon" },
  { title: "OCR", status: "Coming Soon" }
];

const qualifications = ["IGCSE", "GCSE", "AS", "A-Level"];

const BoardExamCard = ({ title, status }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Flex
      display="inline-flex"
      p="9px 8px 7px 8px"
      justify="space-between"
      align="Top"
      borderRadius="8px"
      border="2px solid #E7EAE8"
      width = "280px"
    >
      <Flex direction="column" align="flex-start" gap="2px" width = "auto">
        <Text fontSize="14px" fontWeight="500" color="#000" alignSelf="stretch">
          {title}
        </Text>
        <Text fontSize="12px" fontWeight="350" color="#000">
          Board Exams
        </Text>
        <Flex align="center" gap="2px" alignSelf="stretch" marginTop = "14px">
          {qualifications.map((qual, index) => (
            <Flex key={index} align="center" gap="2px">
              {index > 0 && (
                <Box as="span" width="5px" height="5px" bg="#000000" borderRadius="full" />
              )}
              <Text fontSize="11px" fontWeight="350" color="#000">{qual}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
      
      <Flex
        p="10px"
        justify="center"
        align="center"
        borderRadius="14px"
        bg={status === "Available" ? "#4BFF5C" : "#000000"}
        color={status === "Available" ? "#000" : "#fff"}
        fontSize="12px"
        fontWeight="400"
        minW="60px"  
        height = "34px"
      >
        {status}
      </Flex>
    </Flex>
  );
};

const BoardExamList = () => {
  return (
    <Box display="flex" flexDirection={{ base: "Column", md: "row" }} gap="55px" >
      {boardExams.map((exam) => (
        <BoardExamCard key={exam.title} title={exam.title} status={exam.status} />
      ))}
    </Box> 
  );
};

export default BoardExamList;
