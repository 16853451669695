import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Image,
  VStack,
  InputGroup,
  InputRightElement,
  Checkbox,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import TermsModal from './TermsModal'; // Import the TermsModal component
import ButtonIcon from "../Images/ButtonIcon.svg";
import LoginPageGraphic from "../Images/LoginPageGraphic.svg";
import logo from "./Landing Page/Stand-logo 1.png";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_API_URL } from './config';

const CreateAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const handleAgree = () => {
    setHasAgreed(true); // User has agreed
    setIsModalOpen(false); // Close the modal
  };

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const formLabelStyle = {
    color: "#021A04",
    fontSize: "14px",
    fontWeight: "400",
  };
  const formInputStyle = {
    width: "100%",
    height: "45px",
    borderRadius: "14px",
    border: "1px solid #E7EAE8",
    boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.05)",
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      dob: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      dob: Yup.date().required("Date of birth is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
          "Password must include at least 8 characters, an uppercase letter, a lowercase letter, a number, and a special character"
        ),
    }),
    onSubmit: async (values) => {
      if (!hasAgreed) {
        handleOpenModal(); // Open the modal if not agreed
        return;
      }

      const requestBody = {
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        dob: new Date(values.dob).toISOString(),
        password: values.password,
      };

      try {
        const response = await fetch(`${BASE_API_URL}/api/v1/auth/register-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          setIsAccountCreated(true);
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.message}`);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred while creating the account. Please try again.");
      }
    },
  });

  const loginButtonStyle = {
    display: "flex",
    padding: "10px 24px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "14px",
    background: "#4BFF5C",
    color: "#08250B",
    fontFamily: "Mona Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
    boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.08)",
    width: "100%",
    cursor: "pointer",
    transition: "background 0.3s, color 0.3s",
    _hover: {
      background: "transparent",
      color: "#021A04",
    },
  };

  return (
    <Flex height="100vh" direction={{ base: "column", md: "row" }}>
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" p={{ base: "20px", md: "0" }}>
        <Box width={{ base: "100%", md: "429px" }} textAlign={{ base: "center", md: "left" }} p={{ base: "20px", md: "0" }}>
          <Flex mb="45px" justifyContent={{ base: "center", md: "left" }}>
            <Image src={logo} alt="logo-image" width={{ base: "120px", md: "125px" }} />
          </Flex>

          {isAccountCreated ? (
            <>
              <Heading mb="4px" color="#021A04" fontSize="18px" fontWeight="500">
                Check Your Email
              </Heading>
              <Text color="#B3B3B3" fontSize="14px" fontWeight="400" mb={8}>
                A confirmation email has been sent to your email address. Please check your inbox to complete the registration process.
              </Text>
            </>
          ) : (
            <>
              <Heading mb="4px" color="#021A04" fontSize="18px" fontWeight="500">
                Create Account
              </Heading>
              <Text color="#B3B3B3" fontSize="14px" fontWeight="400" mb={8}>
                Create account in 5 easy steps.
              </Text>
              <form onSubmit={formik.handleSubmit}>
                <VStack alignItems="left" spacing={2}>
                  <FormControl isInvalid={formik.touched.firstname && formik.errors.firstname}>
                    <FormLabel sx={formLabelStyle}>First Name</FormLabel>
                    <Input
                      type="text"
                      name="firstname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstname}
                      sx={formInputStyle}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <Text color="red.400" fontSize="sm">{formik.errors.firstname}</Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={formik.touched.lastname && formik.errors.lastname}>
                    <FormLabel sx={formLabelStyle}>Last Name</FormLabel>
                    <Input
                      type="text"
                      name="lastname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastname}
                      sx={formInputStyle}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <Text color="red.400" fontSize="sm">{formik.errors.lastname}</Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                    <FormLabel sx={formLabelStyle}>Email</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      sx={formInputStyle}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Text color="red.400" fontSize="sm">{formik.errors.email}</Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={formik.touched.dob && formik.errors.dob}>
                    <FormLabel sx={formLabelStyle}>Date of Birth</FormLabel>
                    <Input
                      type="date"
                      name="dob"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dob}
                      sx={formInputStyle}
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <Text color="red.400" fontSize="sm">{formik.errors.dob}</Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                    <FormLabel sx={formLabelStyle}>Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        sx={formInputStyle}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() => setShowPassword(!showPassword)}
                          bg="none"
                          _hover={{ bg: "none" }}
                        >
                          {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {formik.touched.password && formik.errors.password && (
                      <Text color="red.400" fontSize="sm">{formik.errors.password}</Text>
                    )}
                  </FormControl>

                  <Flex align="center" mb={4}>
                    <Checkbox
                      isChecked={hasAgreed}
                      onChange={(e) => {
                        if (!hasAgreed) {
                          handleOpenModal();
                        }
                      }}
                    />
                    <Text fontSize="14px" ml={2} color="#08250B">
                      Agree to Terms and Conditions
                    </Text>
                  </Flex>
                </VStack>
                <Button type="submit" sx={loginButtonStyle} mt={8}>
                  <Flex align="center" gap="2px">
                    <Text>Create Account</Text>
                    <Image src={ButtonIcon} alt="Button icon" />
                  </Flex>
                </Button>
              </form>
            </>
          )}
        </Box>
      </Box>
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" height="100vh" width="100vw" overflow="hidden">
        <Image src={LoginPageGraphic} alt="login-page-graphic" objectFit="cover" height="100%" width="100%" />
      </Box>
      <TermsModal isOpen={isModalOpen} onClose={handleCloseModal} onAgree={handleAgree} />
    </Flex>
  );
};

export default CreateAccount;
