import React, { useState, useRef, useEffect } from "react";
//import { Tooltip } from "@chakra-ui/react"; 
import AddFunctionsArrow from "../Images/adding_functions_arrrow.svg";
import {
  Box,
  Flex,
  Textarea,
  Button,
  Image,
  Input,
  IconButton,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import UploadImageIcon from "../Images/Upload-Image-Icon.svg"; 
import MathInput from './MathInput'; 
import { MathfieldElement } from "mathlive";
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SubmitIcon from "../Images/Submit-Icon.svg";
import UploadImageIconDark from "../Images/Upload-Image-Icon-Dark.svg";
import SubmitIconDark from "../Images/Submit-Icon-Dark.svg";
import html2canvas from "html2canvas"; 
import { Stage, Layer, Line } from "react-konva";
import DotsLoading from "./DotsLoading";

const superscriptMap = {
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
};

const subscriptMap = {
  0: "₀",
  1: "₁",
  2: "₂",
  3: "₃",
  4: "₄",
  5: "₅",
  6: "₆",
  7: "₇",
  8: "₈",
  9: "₉",
};

const transformText = (text) => {
  return text
    .replace(/([a-zA-Z])\^(\d+)/g, (match, base, exp) => {
      const superscript = exp
        .split("")
        .map((char) => superscriptMap[char] || char)
        .join("");
      return `${base}${superscript}`;
    })
    .replace(/([a-zA-Z])_(\d+)/g, (match, base, sub) => {
      const subscript = sub
        .split("")
        .map((char) => subscriptMap[char] || char)
        .join("");
      return `${base}${subscript}`;
    });
};



const QuestionAnswerBox = ({
  questionNum,
  handleSubmitAnswer,
  answerFormat: initialAnswerFormat,
  existingAnswerText = "",  // Pre-fill text from user_question_attempt
  existingImages = [],      // Pre-fill images from user_question_attempt_attachments
  snapshot = null,          // Snapshot from annotation (optional)
  selectedPartGuid,         
  tool,
  strokeWidth,
  timeUp,
  testStarted,
  Loading,
  handleUploadStart,
  handleUploadEnd, 
  testMode,
  uploadInProgressRef,
  setAnswers,
  setIsUploading,
  setImageUpload,
  onAnnotationChange, 
  stageRefs, 
  questions 

  }) => {
  const [answer, setAnswer] = useState(existingAnswerText); // Pre-fill answer text
  const [uploadedImages, setUploadedImages] = useState(
    existingImages.map((image) => ({ name: image, url: encodeURI(image) })) // Pre-fill images
  );
  const [snapshotAdded, setSnapshotAdded] = useState(false); // Flag to track if snapshot has been added
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadIcon, setUploadIcon] = useState(UploadImageIcon);
  const [submitIcon, setSubmitIcon] = useState(SubmitIcon);
  const [answerFormat, setAnswerFormat] = useState(initialAnswerFormat); // Editable answer format
  const [localSnapshot, setLocalSnapshot] = useState(snapshot); // Maintain local snapshot state
  const fileInputRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);
  const stageRef = useRef(null);
  const containerRef = useRef(null);
  const [lastSubmittedAnswer, setLastSubmittedAnswer] = useState(null);
  const [lastSubmittedImages, setLastSubmittedImages] = useState([]);
  const [lastSubmittedLines, setLastSubmittedLines] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [mathBlocks, setMathBlocks] = useState([]); // Stores LaTeX blocks
  const [showMathInput, setShowMathInput] = useState(false);
  const [mathLatex, setMathLatex] = useState("");
  const [blockCount, setBlockCount] = useState(0);
  const [lastSubmittedMathBlocks, setLastSubmittedMathBlocks] = useState([]);
  


  const [tooltipVisible, setTooltipVisible] = useState(false); 
  const tooltipTimeout = useRef(null); 
  const uploadCountRef = useRef(0);
  

  
  
  
  
    
  useEffect(() => {
    if (existingAnswerText) {
      const latexMatches = existingAnswerText.match(/\$\$(.*?)\$\$/g) || [];
  
      const extractedMathBlocks = latexMatches.map((match, index) => ({
        id: index,
        latex: match.replace(/\$\$/g, ""), 
      }));
  
      const cleanAnswerText = existingAnswerText.replace(/\$\$(.*?)\$\$/g, "").trim();
  
      setAnswer(cleanAnswerText);
      setMathBlocks(extractedMathBlocks);
    }
  }, [existingAnswerText]); 
  



  //useEffect(() => {
   // if (timeUp) {
  //   console.log("Time is up! Hiding submit button.");
  //  }
 // }, [timeUp]); 

  
  
  useEffect(() => {
    if (snapshot && snapshot !== localSnapshot && selectedPartGuid) {
      setLocalSnapshot(snapshot);
      setSnapshotAdded(false); // Reset flag when new snapshot is provided
    }
  }, [snapshot, selectedPartGuid, localSnapshot]);

  useEffect(() => {
    if (localSnapshot && !snapshotAdded) {
      const snapshotName = `Snapshot-${selectedPartGuid}`;
      const snapshotExists = uploadedImages.some((image) => image.name === snapshotName);

      // Only add snapshot if it doesn't already exist and it hasn't been added previously in this lifecycle
      if (!snapshotExists) {
        setUploadedImages((prevImages) => [
          ...prevImages,
          { name: snapshotName, url: localSnapshot },
        ]);
        setSnapshotAdded(true); // Set flag to true after adding
      }
    }
  }, [localSnapshot, selectedPartGuid, snapshotAdded]); // Trigger this effect only when `localSnapshot` or `selectedPartGuid` changes

  
  
  const handleAnswerChange = async (e) => {
    const newText = e.target.value;
  
    let annotationImage = null;
  
    if (lines.length > 0) {
      annotationImage = await captureAnnotation();
    }
  
    setAnswer(newText);
  
    setAnswers((prev) => ({
      ...prev,
      [questionNum]: {
        ...prev[questionNum],
        text: newText,
        uploadedImages: prev[questionNum]?.uploadedImages || [],
        annotations: annotationImage ? [{ name: "annotation", url: annotationImage }] : prev[questionNum]?.annotations || [],
      },
    }));
  };
  


  // Handles math input live rendering
  const handleMathInput = (e) => {
    setMathLatex(e.target.value);
  };

  const insertMathBlock = () => {
    if (mathLatex.trim() !== "") {
      const newBlock = { id: blockCount, latex: mathLatex };
      setMathBlocks([...mathBlocks, newBlock]);
      setMathLatex(""); // Clear input
      setBlockCount(blockCount + 1); // Increment ID counter
    }
  };

  const removeMathBlock = (id) => {
    setMathBlocks(mathBlocks.filter((block) => block.id !== id));
  };

 
  const handlePaste = async (event) => {
    const clipboardItems = event.clipboardData.items;
    for (const item of clipboardItems) {
      if (item.type.startsWith("image")) {
        const file = item.getAsFile();
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedImages((prevImages) => [
            ...prevImages,
            { name: `PastedImage-${Date.now()}`, url: e.target.result },
          ]);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && stageRef.current) {
        stageRef.current.width(containerRef.current.clientWidth);
      }
    };
  
    // Set initial width
    handleResize();
  
    // Listen for resize events
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
   // Helper function to detect if click is near a line's points
   const isNearPoint = (point, x, y) => {
    const threshold = 10; // Click proximity threshold
    return Math.abs(point[0] - x) < threshold && Math.abs(point[1] - y) < threshold;
  };

  const handleMouseDown = (e) => {
    const stage = e.target.getStage();
    const { x, y } = stage.getPointerPosition();

    if (tool === "eraser") {
      // Check if any line contains a point near the click position
      const updatedLines = lines.filter((line) =>
        !line.points.some((point, index) => index % 2 === 0 && isNearPoint([line.points[index], line.points[index + 1]], x, y))
      );
      setLines(updatedLines);
    } else if (tool === "pen") {
      isDrawing.current = true;
      const newLine = {
        points: [],
        strokeWidth,
        globalCompositeOperation: "source-over",
        tool: "pen",
      };
      setLines([...lines, newLine]);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) return;
    const stage = stageRef.current.getStage();
    const point = stage.getPointerPosition();
    setLines((prevLines) => {
      const lastLine = prevLines[prevLines.length - 1];
      if (lastLine) {
        lastLine.points = lastLine.points.concat([point.x, point.y]);
        return [...prevLines.slice(0, -1), lastLine];
      }
      return prevLines;
    });
  };


  const handleMouseUp = async () => {
  isDrawing.current = false;
  if (stageRef.current && typeof onAnnotationChange === "function") {
    const dataURL = stageRef.current.toDataURL({ mimeType: "image/png" });
    onAnnotationChange(questionNum, dataURL);
  }
};

  const captureAnnotation = async () => {
    const dataURL = stageRef.current.toDataURL();
    return dataURL;
};


const captureAllAnnotations = async () => {
  // For each question that has annotations (lines), capture and update the answer state.
  for (const question of questions) {
    const questionGuid = question.guid;
    if (lines[questionGuid] && lines[questionGuid].length > 0) {
      const annotationDataURL = await captureAnnotationForQuestion(questionGuid);
      setAnswers((prev) => ({
        ...prev,
        [questionGuid]: {
          ...prev[questionGuid],
          // Merge the annotation into the answer state
          annotations: [{ name: "annotation", url: annotationDataURL }],
        },
      }));
    }
  }
};

const captureAnnotationForQuestion = async (questionGuid) => {
  // Get the stage for this question (you might store it in a ref)
  const stage = stageRefs.current[questionGuid];
  if (stage) {
    const dataURL = stage.toDataURL();
    return dataURL;
  }
  return null;
};



const handleImageUpload = (event) => {
  handleUploadStart();
  setIsUploading(true);

  const validFormats = ["image/png", "image/jpeg", "image/jpg", "image/gif", "image/webp"];
  const files = Array.from(event.target.files);
  const newImages = [];

  uploadCountRef.current += files.length; // Track multiple uploads at once

  files.forEach((file) => {
    if (!validFormats.includes(file.type)) {
      console.error(`Unsupported file format: ${file.type}`);
      alert(`Invalid file format: ${file.name}. Only PNG, JPG, JPEG, GIF, and WEBP are allowed.`);
      uploadCountRef.current -= 1;
      return; // Skip this file
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      newImages.push({ name: file.name, url: reader.result });

      if (newImages.length === files.filter((f) => validFormats.includes(f.type)).length) {
        let annotationImage = null;
        if (lines.length > 0) {
          captureAnnotation().then((capturedImage) => {
            annotationImage = capturedImage;

            setUploadedImages((prevImages) => {
              const updatedImages = [...prevImages, ...newImages];

              setAnswers((prev) => ({
                ...prev,
                [questionNum]: {
                  ...prev[questionNum],
                  text: prev[questionNum]?.text || "",
                  uploadedImages: updatedImages,
                  annotations: annotationImage
                    ? [{ name: "annotation", url: annotationImage }]
                    : prev[questionNum]?.annotations || [],
                },
              }));

              return updatedImages;
            });
          });
        } else {
          // If no annotations, update state immediately
          setUploadedImages((prevImages) => {
            const updatedImages = [...prevImages, ...newImages];

            setAnswers((prev) => ({
              ...prev,
              [questionNum]: {
                ...prev[questionNum],
                text: prev[questionNum]?.text || "",
                uploadedImages: updatedImages,
                annotations: prev[questionNum]?.annotations || [],
              },
            }));

            return updatedImages;
          });
        }
      }

      uploadCountRef.current -= 1;

      if (!document.fullscreenElement && testMode) {
        document.documentElement.requestFullscreen()
          .catch((err) => console.error("Error re-entering full-screen:", err));
      }

      if (uploadCountRef.current === 0) {
        setIsUploading(false);
      }

      handleUploadEnd();
    };

    reader.readAsDataURL(file);
  });

  setTimeout(() => {
    if (!document.fullscreenElement && testMode) {
      document.documentElement.requestFullscreen()
        .catch((err) => console.error("Error forcing full-screen:", err));
    }
  }, 100); // Short delay for UI stability
};
  // Remove image from uploadedImages state
  const handleRemoveImage = (imageName) => { 
    setUploadedImages((prevImages) => prevImages.filter((image) => image.name !== imageName));
  };

  // Remove snapshot from uploadedImages state
  const handleRemoveSnapshot = () => {
    setUploadedImages((prevImages) => prevImages.filter((image) => !image.name.startsWith("Snapshot")));
    setSnapshotAdded(false); // Allow adding snapshot again if removed
  };


  const mathBlocksEqual = (blocks1, blocks2) => {
    if (blocks1.length !== blocks2.length) return false;
    return blocks1.every((block, index) => block.latex === blocks2[index].latex);
  };

  
  const handleSubmit = async () => {

    const formattedMathBlocks = mathBlocks.map(block => block.latex);
    const lastFormattedMathBlocks = lastSubmittedMathBlocks.map(block => block.latex);

    if (
      answer === lastSubmittedAnswer &&
      arraysEqual(uploadedImages, lastSubmittedImages) &&
      JSON.stringify(lines) === JSON.stringify(lastSubmittedLines) &&
      mathBlocksEqual(mathBlocks, lastSubmittedMathBlocks)
    ) {
      setErrorMessage("Your answer has already been submitted. Please make changes to submit again.");
      return;
    }

    setErrorMessage("");

    let updatedImages = [...uploadedImages];
  
    if (lines.length > 0) {
      const annotationImage = await captureAnnotation();
      updatedImages = [...uploadedImages, { name: "annotation", url: annotationImage }];
    }

    const finalAnswer = `${answer} ${mathBlocks.map(block => `$$${block.latex}$$`).join(" ")}`;

    handleSubmitAnswer(questionNum, finalAnswer, updatedImages);

  
    setLastSubmittedAnswer(answer);
    setLastSubmittedImages([...uploadedImages]);
    setLastSubmittedLines([...lines]);
    setLastSubmittedMathBlocks([...mathBlocks]);
  };
  
  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    return a.every((item, index) => item.url === b[index].url);
  };
  
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    onOpen();
  };


  const handleAnswerFormatClick = () => {
    setAnswer(`${answer} ${answerFormat}`);
    setAnswerFormat("");
  };
  
  
  // Show tooltip on mouse enter and hide after a delay
  const handleMouseEnter = () => {
    setTooltipVisible(true);
    tooltipTimeout.current = setTimeout(() => {
      setTooltipVisible(false);
    }, 3000);
  };

  // Hide tooltip immediately on mouse leave
  const handleMouseLeave = () => {
    clearTimeout(tooltipTimeout.current);
    setTooltipVisible(false);
  };

  

  return (
    <Flex flexDirection="column" mt={4} alignItems="right" spacing={4} width = {{ base: "285px", md: "100%" }} zIndex={10} maxWidth="100vw">
      <Box
        ref={containerRef}
        width="100%"
        minHeight="250px" 
        borderRadius="14px"
        border="2px solid #D7D7D7"
        position="relative"
        background="#FCFCFC"
        overflow="hidden"
        padding="12px"
      >
        <Textarea
         value={answer}
          onChange={handleAnswerChange}
          onPaste={handlePaste}
          placeholder="Write your answers here, upload an image answer or annotate below"
          fontFamily="Arial"
          color="black"
          fontSize="14px"
          fontStyle="normal"
          lineHeight="normal"
          border="none"
          resize="none"
          _focus={{ outline: "none" }}
          width="100%"
          height="100%"
          maxHeight="250px"
          overflowY="auto"
          position="relative" // Ensure it serves as a parent for the answerFormat positioning
          onClick={() => {
            if (answerFormat && !answer.includes(answerFormat)) {
              setAnswer((prev) => `${prev} ${answerFormat}`); // Append answer format to the answer
              setAnswerFormat(""); // Clear the answer format after adding
            }
          }}
      
        /> 

        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) return; // Ensure there is a drop target
            const items = Array.from(mathBlocks);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setMathBlocks(items);
          }}
        >
          <Droppable droppableId="mathBlocks" direction="horizontal">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                mt="8px"
                display="flex"
                flexWrap="wrap"
                gap="8px"
                alignItems="center"
                background="white"
                width="100%"
                padding="4px"
              >
              {mathBlocks.map((block, index) => (
                  <Draggable key={block.id.toString()} draggableId={block.id.toString()} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        padding="5px 10px"
                        background="white"
                        borderRadius="4px"
                        position="relative"
                        cursor="grab"
                        _hover={{ '.close-icon': { display: 'block' } }} // Show close icon on hover
                      >
                        <Text fontSize="16px">
                          <math-field readOnly>{block.latex}</math-field>
                        </Text>
                        <IconButton
                          icon={<CloseIcon />}
                          size="xs"
                          color="white"
                          background="black"
                          borderRadius="50%"
                          position="absolute"
                          top="-6px"
                          right="-6px"
                          display="none" // Initially hidden
                          className="close-icon" // Class for targeting in _hover
                          onClick={() => removeMathBlock(block.id)}
                          _hover={{ background: "black" }} // No hover effect change
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>


       
        {answerFormat && !answer.includes(answerFormat) && (
          <Text
            fontSize="14px"
            color="gray"
            position="absolute"
            bottom="275px"
            right="28px"
            cursor="pointer"
            onClick={() => {
              setAnswer((prev) => `${prev}${answerFormat}`); // Add to the answer
              setAnswerFormat(""); // Clear the answer format text
            }}
          >
            {answerFormat}
          </Text>
        )}

         <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ marginTop: "10px" }}
        >
         <Box
          mt={2}aria-autocomplete=""
          padding="8px"
          width="255px"
          position="absolute"
          bottom = {uploadedImages.length > 0 ? "285.9px" : "204px"}
          left="13px"
          background="white"
          border="1px solid #D7D7D7"
          //borderRadius="8px"
          zIndex="10"
          display="flex"
          alignItems="center"
          boxShadow = " none "
          
        >
          <math-field
            value={mathLatex}
            onInput={handleMathInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                insertMathBlock();
              }
            }}
            menuItems="none"
            mathfield-style="black" 
            style={{ fontSize: "16px", width: "100%", border: "none", color: "black", "--keyboard-background-color": "black"}}
          ></math-field>

          
          <IconButton
           icon={<Image src={AddFunctionsArrow} alt="Add Functions" />}
            size="sm"
            variant="ghost"
            onClick={() => {
              insertMathBlock();
              setMathLatex("");
            }}
          />
        </Box>

        <Stage
          ref={stageRef}
          width={containerRef.current ? containerRef.current.clientWidth : 300} // Adaptive width
          height={250}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{ border: "1px solid #d7d7d7", marginTop: "10px" }}
        >
          <Layer>
            {lines.map((line, i) => (
              <Line key={i} points={line.points} stroke="red" strokeWidth={line.strokeWidth} lineCap="round"  lineJoin="round"
              globalCompositeOperation={line.globalCompositeOperation}
              />
            ))}
          </Layer>
        </Stage>
        
        
         {/* Tooltip shown near the annotation area */}
         {tooltipVisible && (
            <Box
              position="absolute"
              top="-30px"
              left="50%"
              transform="translateX(-50%)"
              bg="gray.700"
              color="white"
              px={2}
              py={1}
              borderRadius="4px"
              fontSize="12px"
              zIndex="1000"
              pointerEvents="none"
            >
              Annotate only here
            </Box>
          )}
        </Box>

        


        <Input
          ref={fileInputRef}
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
          display="none"
          onChange={handleImageUpload}
          multiple
        />
        {/* Display uploaded images */}
        {uploadedImages.length > 0 && (
          <Flex wrap="wrap" gap={2} mt={2} justifyContent="flex-start">
            {uploadedImages.map((image, index) => (
              
              <Box key={`${image.name}_${index}`} position="relative" width="auto">
                <Image
                  src={image.url}
                  alt={image.name}
                  boxSize="75px"
                  objectFit="cover"
                  borderRadius="8px"
                  onClick={() => handleImageClick(image.url)}
                  cursor="pointer" 
                />
                <IconButton
                  icon={<CloseIcon />}
                  size="xs"
                  color="white"
                  background="black"
                  borderRadius="50%"
                  position="absolute"
                  top="5px"
                  right="5px"
                  onClick={() => handleRemoveImage(image.name)}
                  _hover={{ background: "#4BFF5C" }}
                />
              </Box>
            ))}
          </Flex>
        )}
      </Box>

      {errorMessage && (
          <Text color="red.500" fontSize="14px" mt={2}>
            {errorMessage}
          </Text>
      )}


      {/* Modal for viewing full image */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && (
              <Image src={selectedImage} alt="Selected" width="100%" objectFit="contain" />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <HStack mt={4} spacing={4} alignItems="right">
        <Button
          padding="8px 18px"
          alignItems="center"
          gap="4px"
          borderRadius="14px"
          background="var(--Main-font-color, #021A04)"
          color="var(--Primary, #F1F1F1)"
          fontSize={{ base: "12px", md: "14px" }} 
          fontWeight="400"
          onClick={() => fileInputRef.current.click()}
          onMouseEnter={() => setUploadIcon(UploadImageIconDark)}
          onMouseLeave={() => setUploadIcon(UploadImageIcon)}
          _hover={{
            background: "var(--Primary, #F1F1F1)",
            color: "var(--Main-font-color, #021A04)",
            boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)",
          }}
        >
          <Image src={uploadIcon} alt="Upload Answer" />
          Upload Answer Photo
        </Button>

        {(!testStarted && !timeUp) && (
        <Button
          padding="8px 18px"
          alignItems="center"
          gap="4px"
          borderRadius="14px"
          background="var(--Main-font-color, #021A04)"
          color="var(--Primary, #F1F1F1)"
          fontSize={{ base: "12px", md: "14px" }}
          fontWeight="400"
          onClick={!Loading ? handleSubmit : null} // Prevent clicking when loading
          onMouseEnter={() => !Loading && setSubmitIcon(SubmitIconDark)} // Only change icon on hover if not loading
          onMouseLeave={() => !Loading && setSubmitIcon(SubmitIcon)} // Only reset icon if not loading
          _hover={{
            background: !Loading ? "var(--Primary, #F1F1F1)" : "var(--Main-font-color, #021A04)", // No hover effect when loading
            color: !Loading ? "var(--Main-font-color, #021A04)" : "var(--Primary, #F1F1F1)",
            boxShadow: !Loading ? "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)" : "none",
          }}
          >
          {!Loading && <Image src={submitIcon} alt="Submit Answer" />} {/* Hide icon when loading */}
          {Loading ? "Submitting ..." : "Submit Answer"}
        </Button>

        )}
      </HStack>
      {Loading && <DotsLoading />}

      
    </Flex>
  );
};

export default QuestionAnswerBox;