import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import FeedBack from "../FeedBack"; 

const FeedbackCarousel = ({ feedbackList }) => {
  return (
    <Box width="100%" overflow="hidden" position="relative" marginTop = "85px">
      
      <Box className="scroll-left" >
        <Flex className="carousel" >
          {[...feedbackList, ...feedbackList] 
            .map((item, index) => (
              <Flex key={index} minWidth="425px" gap = "4px" minHeight="225px">
                <FeedBack feedbackData={item} totalMarks = {item.totalMarks} />
              </Flex>
            ))}
        </Flex>
      </Box>

    </Box> 
  );
};

export default FeedbackCarousel;
