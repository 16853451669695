import React, { useState } from "react";
import { Box, Flex, VStack, Heading, Text, Button, Image } from "@chakra-ui/react";
import ProductImage from "./ProductImage.png";
import ExpandArrow from "./ExpandArrow.svg";
import CollapseArrow from "./CollapseArrow.svg";
import ArrowButton from "./CallToActionArrowButton.svg";
import { useNavigate } from "react-router-dom"; 

const Product = () => {
  const [expandedStep, setExpandedStep] = useState(null);

  const steps = [
    {
      title: "1. Choose Your Exam Board & Qualification",
      description: "Whether you're preparing for Cambridge IGCSE, Edexcel GCSE, or AQA A-levels, select your board and qualification in one click.",
    },
    {
      title: "2. Solve Past Papers",
      description: "Get access to hundreds of past papers to practice with and improve your skills.",
    },
    {
      title: "3. Get Instant Feedback",
      description: "Receive AI-driven feedback on your answers with tips on how to improve. Be confident the AI knows your marking scheme and here to help you",
    },
    {
      title: "4. Track Your Progress",
      description: "Monitor your performance over time and identify areas for improvement.",
    },
    {
      title: "5. Personalized Study Suggestions",
      description: "Get tailored recommendations to focus on topics where you need the most help.",
    },
  ];

  const toggleExpand = (index) => {
    setExpandedStep(expandedStep === index ? null : index);
  }; 

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };


  return (
    <Box width="100%" paddingX={{ base: "20px", md: "45px" }} marginTop="60px">
      <Flex
        flexDirection={{ base: "column", lg: "row" }}  
        gap={{ base: "40px", lg: "60px" }}  
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <VStack
          alignItems="flex-start"
          width={{ base: "100%", lg: "50%" }}  
          gap="14px"
        >
          <Heading color="#021A04" fontSize={{ base: "18px", md: "32px" }} fontWeight="600">
            How We Help You Succeed
          </Heading>
          <Text color="#021A04" fontSize={{ base: "14px", md: "16px" }} fontWeight="400">
            Our platform simplifies exam preparation with easy access to past papers, instant feedback, and powerful tools to track your performance.
          </Text>
          <Button variant="unstyled" textDecoration="underline" onClick = {handleLoginClick}>
            <Flex gap="4px" alignItems="center" justifyContent="flex-start">
              <Heading color="#021A04" fontSize="14px" fontWeight="400" sx={{ _hover: { opacity: 0.7 } }} >
                Try it for Free
              </Heading>
              <Image src={ArrowButton} alt="get-started-arrow-button-icon" />
            </Flex>
          </Button>
          <Image src={ProductImage} alt="product-image" width="100%" /> 
        </VStack>

        <VStack
          width={{ base: "100%", lg: "45%" }}  
          spacing="14px"
        >
          {steps.map((step, index) => (
            <Box
              key={index}
              padding="15px 10px"
              backgroundColor={expandedStep === index ? "#ffffff" : "#ffffff"}
              color={expandedStep === index ? "#000000" : "#000000"}
              cursor="pointer"
              onClick={() => toggleExpand(index)}
              borderRadius="14px"
              border= "2px solid #E7EAE8"
              transition="background-color 0.3s ease-in-out"
              width="100%"  // Ensure full width for boxes
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Heading fontSize={{ base: "14px", md: "16px" }} fontWeight="400" color="#282A29">
                  {step.title}
                </Heading>
                <Image
                  src={expandedStep === index ? CollapseArrow : ExpandArrow}
                  alt="expand-collapse-arrow"
                />
              </Flex>
              {expandedStep === index && (
                <Text color="#282A29" fontSize={{ base: "12px", md: "16px" }} fontWeight="400" marginTop="10px">
                  {step.description}
                </Text>
              )}
            </Box>
          ))}
        </VStack>
      </Flex>
    </Box>
  );
};

export default Product;
