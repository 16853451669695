import React from "react";
import { Box, Text, Image, VStack, Heading, Flex, SimpleGrid } from "@chakra-ui/react";
import AiPoweredIcon from "./AiPoweredIcon.svg";
import TailoredInsightsIcon from "./TailoredInsightsIcon.svg";
import TeacherGroupIcon from "./TeacherGroupIcon.svg";
import FreemiumModelIcon from "./FreemiumModelIcon.svg";
import TestModeIcon from "./TestModeIcon.svg";


const Features = () => {
  const features = [
    {
      title: "AI-Powered Marking",
      description: "Get accurate, instant feedback on every paper you solve.",
      image: AiPoweredIcon
    },
    { 
      title: "Tailored Insights",
      description: "Know exactly where you need to focus and improve. ",
      image: TailoredInsightsIcon
    },
    {
      title: "Teacher-Approved Solutions",
      description: "Developed with educators to ensure the best outcomes for students. ",
      image: TeacherGroupIcon
    },
    {
      title: "Freemium Model  ",
      description: "Access unlimited past papers for free, or unlock premium features to boost your performance. ",
      image: FreemiumModelIcon
    }, 
    {
      title: "Test Mode ",
      description: "Experience real exam conditions with Test Mode: timed sessions, full-screen lock, no copy-pasting, controlled navigation, and optional session recording for a secure and fair testing environment.  ",
      image: TestModeIcon
    },
  ];

  return (
    <Box width="100%" paddingX={{ base: "20px", md: "45px" }} marginTop="60px" >
      <VStack spacing={8} alignItems="center">
        <Flex
          flexDirection="column"
          alignItems="center"
          width={{ base: "100%", lg: "60%" }} 
          textAlign="center"
          gap="14px"
          marginBottom="60px" 
        >
          <Heading color="#021A04" fontSize={{ base: "18px", md: "32px" }} fontWeight="600">
            Built by Teachers, Trusted by Students
          </Heading>
          <Text color="#021A04" fontSize={{ base: "14px", md: "16px" }} fontWeight="400">
            With over 13 years of experience in the classroom, we understand what it takes to succeed in these exams. Our platform is designed to simplify studying, reduce stress, and improve results for students taking Cambridge, Edexcel, OCR, and AQA exams. Here’s why we stand out:
          </Text>
        </Flex>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="40px" spacingY="40px" width="100%">
          {features.map((feature, index) => (
            <Flex
              key={index}
              flexDirection="column"
              gap="14px"
              alignItems="flex-start"
              width="100%"
              borderRadius="14px"
              border="1px solid #D7D7D7"
              padding="18px 17px"
            >
              <Flex flexDirection="row" gap="14px" alignItems="center">
                <Image src={feature.image} alt={feature.title} />
                <Heading color="#021A04" fontSize={{ base: "14px", md: "18px" }} fontWeight="500">
                  {feature.title}
                </Heading>
              </Flex>
              <Text color="#021A04" fontSize={{ base: "12px", md: "16px" }} fontWeight="400">
                {feature.description}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default Features;
