import React from "react";
import { Box, Flex, Heading, Button, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Arrow from "./CallToActionArrowButton.svg";
import CallToActionBackground from "./CallToActionBackgroundImage.svg";

const ToCallAction = () => {

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <Box
      bgImage={`url(${CallToActionBackground})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize = "cover"
      width="100%"
      marginTop="154px"
      paddingX={{ base: "20px", md: "45px" }}
    >
      <Flex flexDirection="column" alignItems="center" paddingY="154px">
        <Heading
          color="#000000"
          fontSize={{ base: "24px", md: "32px" }}
          fontWeight="600"
          textAlign="center"
          marginBottom="24px"
        >
          Ready to Improve Your Exam Performance
        </Heading>
        <Button
        variant="unstyled"
        borderRadius="14px"
        bg="#000000"
        border="1px solid"
        borderColor="#4BFF5C"
        color="#ffffff"
        _hover={{
        color: "#000000",
        borderColor: "#F1F1F1",
        backgroundColor: "transparent",
        }}
        width={{ base: "180px", md: "201px" }} // Adjusted width for different screens
        onClick = {handleLoginClick}
        >
          <Flex padding="10px 34px" gap="4px" alignItems="center" justifyContent="center">
            <Heading fontSize="12px" fontWeight="400" fontStyle="normal">
              Sign Up Free Today
            </Heading>
            <Image src={Arrow} alt="get-started-arrow-button-icon" />
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
};

export default ToCallAction;
