import React from "react";
import { Box, Image, Flex, Button, HStack, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
//import HeroImage from "./HeroImage.svg";
import Arrow from "./CallToActionArrowButton.svg";
//import CambridgeLogo from "./CambridgeLogo.svg";
//import EdexcelLogo from "./EdexcelLogo.svg";
//import OCRLogo from "./OCRLogo.svg";
//import AQALogo from "./AQAlogo.svg"; 
import FeedbackCarousel from "./FeedbackCarousel"; 
import BoardExamList from "./BoardExamCard"; 


const Hero = () => {
  const navigate = useNavigate(); 

  const handleLoginClick = () => {
    navigate("/login");
  }; 

  const feedbackList = [
    {
      feedbackStatus: "INCORRECT",
      feedback: "Your answer is too brief. Try to include more details about zero login, such as its role in security or authentication.",
      mark: 0, 
      totalMarks: 3 
      
    },
    {
      feedbackStatus: "PARTIALLY CORRECT",
      feedback: "You correctly identified that GUIs are easier for beginners [1 mark] and that they save time as no commands are required to learn [1 mark]. You also mentioned the drawback of requiring high storage capacity [1 mark]. However, to gain more marks, you could have discussed other benefits such as intuitive commands or drawbacks like high RAM usage.",
      mark: 3, 
      totalMarks: 5 
    },
    {
      feedbackStatus: "CORRECT",
      feedback: "You correctly determined the number of neutrons [1 mark]. You also correctly identified the relationship between electrons and protons [1 mark]. Well done!",
      mark: 2,
      totalMarks: 2 
    },
    {
      feedbackStatus: "PARTIALLY CORRECT",
      feedback: "Your answer is partially correct. You mentioned 'time taken,' which is good. However, the definition should focus on the number of radioactive nuclei or atoms halving, rather than the isotope's lifespan.",
      mark: 1
    },
    {
      feedbackStatus: "PARTIALLY CORRECT",
      feedback: "You correctly identified the formula P = F/A [1 mark]. However, to gain full marks, you needed to calculate using the appropriate values and provide the final pressure answer.",
      mark: 1 
    }, 
    {
      feedbackStatus: "CORRECT",
      feedback: "Excellent work! You calculated the number of turns on the primary coil correctly.",
      mark: 2,
      totalMarks: 2 
    },
    {
      feedbackStatus: "PARTIALLY CORRECT",
      feedback: "You correctly identified the formulae P = F/A [1 mark] and F = mg [1 mark]. However, to gain full marks, you needed to calculate using the appropriate values and provide the final pressure answer.",
      mark: 2
    },
    {
      feedbackStatus: "INCORRECT",
      feedback: "Your calculation setup is correct for finding \( h \) [1 mark], but you did not provide the final answer.",
      mark: 0, 
      totalMarks: 2
    },
    {
      feedbackStatus: "CORRECT",
      feedback: "You have correctly identified the product of photosynthesis as oxygen. Well done!",
      mark: 1,
      totalMarks: 1 
    },
    {
      feedbackStatus: "CORRECT",
      feedback: "You correctly applied the method \\(\\frac{4}{7} \\times \\frac{1}{8}\\) [1 mark] and arrived at the correct answer \\(\\frac{1}{14}\\) [1 mark]. Well done!",
      mark: 2,
      totalMarks: 2 
    },
    {
      feedbackStatus: "INCORRECT",
      feedback: "Your calculation setup is correct for finding \( h \) [1 mark], but you did not provide the final answer.",
      mark: 0, 
      totalMarks: 2
    },
    {
      feedbackStatus: "PARTIALLY CORRECT",
      feedback: "You correctly identified homeostasis [1 mark] and the detection of external temperature by receptors [1 mark]. Try to include more details about the process, such as nerve impulses or specific responses to temperature changes.",
      mark: 2
    }, 
    {
      feedbackStatus: "INCORRECT",
      feedback: "Your answer is incorrect. It seems you calculated the speed incorrectly. Try using the formula \\((\\Delta v = a \\times \\Delta t)\\) with the appropriate values.",
      mark: 0,
      totalMarks: 2 
    },
    {
      feedbackStatus: "INCORRECT",
      feedback: "Your answer is close, but to gain marks, you could mention that heated or warmer air is less dense, which causes it to rise.",
      mark: 0,
      totalMarks: 3
    },
    {
      feedbackStatus: "INCORRECT",
      feedback: "Your answer is incorrect because it doesn't address the changes in volume and temperature. The correct explanation involves the balance between increased temperature causing harder collisions and larger volume causing less frequent collisions.",
      mark: 0,
      totalMarks: 4
    },
    {
      feedbackStatus: "INCORRECT",
      feedback: "Your answer is incorrect. It seems you provided a graph instead of calculating the angle. To solve the problem, you need to use trigonometry to find the angle that diagonal \\( BH \\) makes with the face \\( ADHE \\). Consider using the tangent formula provided in the marking scheme.",
      mark: 0,
      totalMarks: 2 
    },
    {
      feedbackStatus: "INCORRECT",
      feedback: "You attempted to calculate the decrease but only did it for one year instead of four. To improve, apply the exponential decrease over four years as indicated in the question.",
      mark: 0,
      totalMarks: 2 
    }
  
  ];
  

  return (
    <Box width="100%" bg="#ffffff" paddingY={{ base: "50px", md: "75px" }}  
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        margin="auto"
        gap="75px"  
        paddingX={{ base: "20px", md: "50px" }}
        minHeight="80vh"
      >
        <Flex
          flexDirection={{ base: "column", lg: "coloumn" }} 
          alignItems="center"
          justifyContent="space-between"
          width="90%"
          marginTop={{ base: "85px", lg: "100px" }} 
        >
          <Flex
            flexDirection="column"
            width={{ base: "90%", md: "80%", lg: "70%" }}  
            textAlign="center"
            alignItems="center"

            gap="20px"
          >
            <Heading
              color="#282A29"
              fontSize={{ base: "20px", md: "32px" }} 
              fontWeight="600"
            >
              Solve Questions with Instant Ai Feedback and Personalized
              Study Tools. Be confident, the Ai knows your marking sheme 
            </Heading>
            <Text
              color="#424443"
              fontSize={{ base: "14px", md: "16px" }} 
              fontWeight="400" 
            >
              Solve past papers from Cambridge, Edexcel, OCR, and AQA. Get precise,
              detailed feedback on every answer, track your progress, and focus on
              the topics that matter most to your success.
            </Text>
            <Button
              variant="unstyled" 
              borderRadius="14px"
              bg="#4BFF5C"
              width="201px"
              border="1px solid"
              borderColor="#4BFF5C"
              color="#021A04"
              _hover={{
                color: "#282A29",
                borderColor: "#282A29",
                backgroundColor: "transparent",
              }}
              margin={{ base: "auto", lg: "0" }} 
              onClick = {handleLoginClick}
            >
              <Flex
                padding="10px 34px"
                gap="4px"
                height="35px"
                alignItems="center"
                justifyContent="center"
              >
                <Heading fontSize="14px" fontWeight="400">
                  Solve Now For Free
                </Heading>
                <Image src={Arrow} alt="get-started-arrow-button-icon" />
              </Flex>
            </Button>
          </Flex>
        
        
        </Flex>

    <Flex
      
    >
      <BoardExamList />

    </Flex>

      </Flex>
      <FeedbackCarousel feedbackList={feedbackList}   />

    </Box>
  );
};

export default Hero;
