import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const progressAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

const LoadingOverlay = () => (
  <Flex
    position="fixed"
    top="0"
    left="0"
    width="100vw"
    height="100vh"
    backgroundColor="#ffffff"
    justifyContent="center"
    alignItems="center"
    zIndex="1000"
    direction="column"
     >

    <Text color="#282A29" fontSize="24px" fontWeight="500" mb="20px">
      Logging in...
    </Text>
    <Box
      width="20%" 
      height="8px" 
      bg="#ffffff"  
      borderRadius="4px"
      overflow="hidden"
      mt="20px"
    >
      <Box
        height="100%"
        backgroundColor="#4BFF5C" 
        borderRadius="4px"
        animation={`${progressAnimation} 5s linear forwards`} 
      />
    </Box>
  </Flex>
);

export default LoadingOverlay;
