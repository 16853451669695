import React, { useState, useEffect } from "react";
import { Box, HStack, VStack, Text, Heading, Flex, Image, Input, Button, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  IconButton
  } from "@chakra-ui/react";
import CorrectAnswerIcon from "../Images/CorrectAnswerIcon.svg"; 
import WrongAnswerIcon from "../Images/WrongAnswerIcon.svg"; 
import PartialAnswerIcon from "../Images/PartiallyCorrectAnswerIcon.svg"; 
import axios from "axios";
import AddIcon from "../Images/add-icon-dark.svg";
import { CloseIcon } from "@chakra-ui/icons";
import { BASE_API_URL } from './config';
import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';


const parseFeedback = (text, feedbackStatus) => {
  let cleanedText = text;

  if (feedbackStatus === "PARTIALLY CORRECT") {
    cleanedText = cleanedText.replace(/\[\d+\s*marks?\]\.?\s*/gi, "").trim();
  }

  const parts = cleanedText.split(/(\$\$.*?\$\$|\$.*?\$|\\\(.*?\\\)|\\\[.*?\\\])/g);

  return parts.map((part, index) => {
    // $$ ... $$
    if (/^\$\$.*\$\$$/.test(part)) {
      const mathContent = part.slice(2, -2).trim();
      return <InlineMath key={index} math={mathContent} />;
    }
    // $ ... $
    if (/^\$.*\$$/.test(part)) {
      const mathContent = part.slice(1, -1).trim();
      return <InlineMath key={index} math={mathContent} />;
    }
    // \( ... \)
    if (/^\\\(.*\\\)$/.test(part)) {
      const mathContent = part.slice(2, -2).trim();
      return <InlineMath key={index} math={mathContent} />;
    }
    // \[ ... \]
    if (/^\\\[.*\\\]$/.test(part)) {
      const mathContent = part.slice(2, -2).trim();
      return <InlineMath key={index} math={mathContent} />;
    }

    // Plain text
    return <span key={index}>{part}</span>;
  });
}; 

const FeedBack = ({ loading, questionAttemptGuid, feedbackData, totalMarks, userType }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apiError, setApiError] = useState(null);
  const [newMark, setNewMark] = useState("");
  const [error, setError] = useState(null);
  const [updatedFeedback, setUpdatedFeedback] = useState(feedbackData);


  const { sampleImage, feedback, feedbackStatus, mark } = updatedFeedback;

  const [bgColor, setBgColor] = useState("#EDFFEE");
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    setUpdatedFeedback(feedbackData);
  }, [feedbackData]);

  useEffect(() => {
    setUpdatedFeedback(feedbackData);
  }, [feedbackData]);

  useEffect(() => {
    setUpdatedFeedback(feedbackData);

    // Handle API error in feedbackData
    if (feedbackData?.detail) {
      setApiError(feedbackData.detail); // Set error message
      onOpen(); // Open modal
    }
  }, [feedbackData, onOpen]);

  useEffect(() => {
    // Adjust background color based on feedback status
    if (feedbackStatus === "INCORRECT") {
      setBgColor("#FFFFFF");
    } else if (feedbackStatus === "PARTIALLY CORRECT") {
      setBgColor("#FFFFFF");
    } else {
      setBgColor("#FFFFFF");
    }
  }, [feedbackStatus]);
 
  
  const handleUpgradeClick = async () => {
    const token = `Bearer ${localStorage.getItem("accessToken")}`;

    try {
      const checkoutResponse = await fetch(
        `${BASE_API_URL}/api/v1/user-subscription-plans/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            subscription_plan_guid: "298eb053-7c3a-45d6-967f-f73508517aa9",
            stripe_price_id: "price_1QkqC7C7LkUNUnN8V3nYcQd8",
            renewal_type: "MONTHLY",
          }),
        }
      );

      if (!checkoutResponse.ok) {
        throw new Error("Failed to create checkout session");
      }

      const checkoutData = await checkoutResponse.json();
      const checkoutUrl = checkoutData.checkout_url;

      window.location.href = checkoutUrl;
    } catch (err) {
      console.error("Error creating checkout session:", err.message);
    }
  };

  
  const handleSubmit = async () => {
    try {
      const requestBody = {
        feedback_mark: parseInt(newMark, 10),
        completion_status: "COMPLETE",
      };

      const response = await axios.put(
        `${BASE_API_URL}/api/v1/user-question-attempts/${questionAttemptGuid}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUpdatedFeedback((prevFeedback) => ({
        ...prevFeedback,
        feedbackStatus: response.data.feedback_correctness,
        mark: response.data.feedback_mark,
      }));

      setError(null);
    } catch (err) {
      setError("There was an error submitting your mark.");
      console.error(err);
    }
  };

  
  const handleMarkChange = (e) => {
    setNewMark(e.target.value);
  }; 

  if (loading) {
    return true;
  }
  
  if (apiError) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="white" borderRadius="12px" padding="20px">
          <Flex justifyContent="flex-end">
            <IconButton
              icon={<CloseIcon />}
              onClick={onClose}
              variant="ghost"
              aria-label="Close modal"
              size="sm"
            />
          </Flex>
          <ModalHeader textAlign="center" fontSize="18px" fontWeight="600">
            {apiError}
          </ModalHeader>
          <ModalBody>
            <Text textAlign="center" fontSize="14px" mb="4px">
              Upgrade to premium to access this feature and get more detailed feedback.
            </Text>
            <Flex justifyContent="center">
              <Button
                marginTop = " 24px "
                variant="unstyled"
                borderRadius="14px"
                bg="#4BFF5C"
                width="180px"
                border="1px solid"
                borderColor="#4BFF5C"
                color="#021A04"
                _hover={{
                  color: "#021A04",
                  borderColor: "#F1F1F1",
                  backgroundColor: "transparent",
                }}
                onClick={handleUpgradeClick}
              >
                <Flex padding="14px" gap="4px" display="inline-flex">
                  <Heading fontSize="12px" fontWeight="400">Upgrade to premium</Heading>
                  <Image src={AddIcon} alt="upgrade-icon" boxSize = "14px " marginBottom = " 34px " />
                </Flex>
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box width={{ base: "350px", md: "548px" }} padding="24px" bg={bgColor} borderRadius="14px" marginTop="24px" position="relative" border = "2px solid #E7EAE8">
      <HStack justify="space-between">
        <Flex display="inline-flex" gap="4px" marginBottom="14px">
          <Image
            src={
              feedbackStatus === "CORRECT"
                ? CorrectAnswerIcon
                : feedbackStatus === "PARTIALLY CORRECT"
                ? PartialAnswerIcon
                : WrongAnswerIcon
            }
            alt={`${feedbackStatus}-icon`}
          />
          <Heading color="#021A04" fontSize="14px" fontWeight="500">
            {feedbackStatus}
          </Heading>
        </Flex>
        <Flex>
          {feedbackStatus !== "PARTIALLY CORRECT" && (
            <Heading color="#021A04" fontSize="12px" fontWeight="300">
              {`${mark} marks / ${totalMarks}`}
            </Heading>
          )}
        </Flex>
      </HStack>

      <VStack spacing={1} alignItems="left">
        <Heading color="#021A04" fontSize="14px" fontWeight="500">
          Feedback
        </Heading>

        <Box position="relative" width="100%">
          {/* Normal Feedback if user has permission */}
          {feedbackStatus === "PARTIALLY CORRECT" ? (
            <Text color="#021A04" fontSize="14px" fontWeight="250">
              {parseFeedback(feedback, feedbackStatus)}
            </Text>
          ) : (
            <Text color="#021A04" fontSize="14px" fontWeight="250">
            {parseFeedback(feedback, feedbackStatus)}
            </Text>
          )}

          {sampleImage && sampleImage !== "no image url" && (
            <>
              <Heading color="#021A04" fontSize="12px" fontWeight="400">
                This type of question might not be answered correctly by AI. Please mark yourself based on the sample answer image below:
              </Heading>
              <Image src={sampleImage} alt="sample-image" />
              <Input
                type="number"
                placeholder="Please enter your new mark here"
                value={newMark}
                onChange={handleMarkChange}
                borderRadius="14px"
                border="0.5px solid var(--Main-font-color, #021A04)"
                mt="12px"
              />
              <Button
                onClick={handleSubmit}
                color="#F1F1F1"
                fontSize="14px"
                fontWeight="400"
                mt="14px"
                bg="#070707"
                borderRadius="14px"
                _hover={{
                  background: "var(--Primary, #F1F1F1)",
                  color: "var(--Main-font-color, #021A04)",
                  boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)",
                }}
              >
                Submit Mark
              </Button>
              {error && <Text color="red.500">{error}</Text>}
            </> 
          )}
        
    
        </Box>
      </VStack>
    </Box>
  );
};

export default FeedBack;
