import React, { useEffect, useState } from "react";
import { Box, Spinner, Text, Flex, Heading, Image, Button, Menu, MenuButton, MenuList, MenuItem, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import CreateUserAttempt from "./CreateUserAttempt";
import FilterIcon from "../Images/Filter-Icon.svg";
import MenuArrow from "../Images/Menu_Arrow.svg";
import { BASE_API_URL } from './config';

const MyPaper = () => {
    const [userAttempts, setUserAttempts] = useState([]);
    const [filteredAttempts, setFilteredAttempts] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [showFilters, setShowFilters] = useState(false);

    const [selectedSubject, setSelectedSubject] = useState("Subject");
    const [selectedBoard, setSelectedBoard] = useState("Board Exam");
    const [selectedQualification, setSelectedQualification] = useState("Qualification");
    const [selectedYear, setSelectedYear] = useState("Intake Year");

    const boardType = ["Cambridge", "Edexcel", "AQA", "OCR"];
    const qualifications = ["IGCSE", "GCSE", "AS", "ALevel"];
    const intakeYear = ["May/June", "Oct/Nov", "Feb/March"];

    const handleFilterToggle = () => {
        setShowFilters(!showFilters);
    };

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await fetch(`${BASE_API_URL}/api/v1/subjects/`);
                if (!response.ok) {
                    throw new Error("Failed to fetch from the API");
                }
                const subjectsList = await response.json();
                const subjectAndCode = subjectsList.map(subject => `${subject.name} (${subject.code})`);
                setSubjects(subjectAndCode);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchSubjects();
    }, []);

    useEffect(() => {
        const fetchUserAttempts = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const tokenType = localStorage.getItem('tokenType');
                if (!accessToken || !tokenType) {
                    throw new Error('Authorization token not found');
                }
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `${tokenType} ${accessToken}`,
                };
                const response = await fetch(`${BASE_API_URL}/api/v1/user-paper-attempts/detail/`, {
                    method: 'GET',
                    headers: headers,
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch from the API');
                }
                const data = await response.json();
                setUserAttempts(data);
                setFilteredAttempts(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserAttempts();
    }, []);

    const handleFilterPapers = () => {
        const filtered = userAttempts.filter((attempt) => {
            const selectedSubjectName = selectedSubject.split(" (")[0];
            const subjectMatch = selectedSubject !== "Subject"
                ? attempt.subject_name?.toLowerCase() === selectedSubjectName.toLowerCase()
                : true;
            const boardMatch = selectedBoard !== "Board Exam"
                ? attempt.board_name === selectedBoard
                : true;
            const qualificationMatch = selectedQualification !== "Qualification"
                ? attempt.qualification === selectedQualification
                : true;
            const yearMatch = selectedYear !== "Intake Year"
                ? attempt.intake_year?.toLowerCase().includes(selectedYear.toLowerCase().trim())
                : true;

            return subjectMatch && boardMatch && qualificationMatch && yearMatch;
        });
        setFilteredAttempts(filtered);
    };

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Text color="red.500"> Error: {error} </Text>;
    }

    return (
        <Flex direction="column" width="100%" px={{ base: 4, md: 8 }}>
            <Box mb={4}>
                <Flex flexDirection="row" alignItems="center" gap={2}>
                    <Heading color="#021A04" fontSize="14px" fontWeight="400">
                        Filter By
                    </Heading>
                    <Button onClick={handleFilterToggle} variant="unstyled">
                        <Image src={FilterIcon} alt="filter-icon" />
                    </Button>
                </Flex>

                {showFilters && (
                    <Flex mt={4} flexWrap="wrap" gap={4} justifyContent="space-between">
                        {[{ label: selectedSubject, items: subjects, setter: setSelectedSubject },
                          { label: selectedBoard, items: boardType, setter: setSelectedBoard },
                          { label: selectedQualification, items: qualifications, setter: setSelectedQualification },
                          { label: selectedYear, items: intakeYear, setter: setSelectedYear }
                        ].map((filter, index) => (
                            <Menu key={index}>
                                <MenuButton
                                    as={Button}
                                    variant="menuButton"
                                    width={{ base: "100%", md: "auto" }}
                                    maxW="200px"
                                    textAlign="center"
                                    fontSize="14px"
                                    fontWeight="400"
                                    background="white"
                                    border="2.333px solid #E7EAE8"
                                    borderRadius="9.333px"
                                    _hover={{ background: "white" }}
                                    _focus={{ boxShadow: "none" }}
                                    rightIcon={<img src={MenuArrow} alt="menu arrow" style={{ marginLeft: '8px' }} />}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    {filter.label}
                                </MenuButton>
                                <MenuList background="white" color="#021A04" textAlign="center" fontSize="12px" fontWeight="300">
                                    {filter.items.map((item, idx) => (
                                        <MenuItem key={idx} onClick={() => filter.setter(item)}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        ))}
                        <Button
                            onClick={handleFilterPapers}
                            display="inline-flex"
                            padding="8.864px 30.136px"
                            borderRadius="12.409px"
                            background="#021A04"
                            color="#F1F1F1"
                            fontSize="14px"
                            fontWeight="400"
                            _hover={{
                                background: "#F1F1F1",
                                color: "#021A04",
                                boxShadow: "0px 0px 5.9px rgba(0, 0, 0, 0.14)"
                            }}
                        >
                            Filter Papers
                        </Button>
                    </Flex>
                )}
            </Box>

            {/* User Attempts Section */}
            <Box borderTop="2px solid #E7EAE8" mt={4}>
                {filteredAttempts.length > 0 ? (
                    <SimpleGrid 
                    columns={{ base: 1, sm: 2, md: 3, lg: 4 }} 
                    spacing={{ base: 4, md: 6 }} 
                    mt={4}

                >
                    {filteredAttempts.map((attempt, index) => (
                        <CreateUserAttempt
                            key={index}
                            subjectName={`${attempt.subject_name} ${attempt.subject_code}`}
                            paperName={`Paper ${attempt.paper_name.split(' ')[1]}${attempt.paper_variant} ${attempt.exam_series_month === "MAY_JUNE" ? "May/June" : attempt.exam_series_month} ${attempt.exam_series_year}`}
                            numOfQuestionsLeft={attempt.questions_remaining}
                            mark={attempt.total_scored_marks}
                            totalMark={attempt.total_marks}
                            inProgress={attempt.completion_status !== "COMPLETE"}
                            progressPercentage={Math.round(attempt.percentage_complete)}
                            user_paper_attempt_guid={attempt.guid}
                        />
                    ))}
                </SimpleGrid>
                ) : (
                    <Text mt={4} color="#021A04" fontSize="20px" fontWeight="200">
                        No papers Solved yet
                    </Text>
                )}
            </Box>
        </Flex>
    );
};

export default MyPaper;
